import { FlightPaxDetails } from "tl-api-doc/typescript-generator";
import { ICustomer, IFrequentFlyer } from "./types/fly-order.types";
import Http from "common/http/createHttp";

export default ({ http }: { http: typeof Http }) => ({
  getAgencyLogo: (agencyId: string, size = "") => {
    const query: string = size ? `${agencyId}?size=_${size}` : agencyId;
    return http.getImg(`/logo/${query}`, {
      responseType: "blob"
    });
  },

  findCountry: (request: { term: string; locale: string }) => {
    return http.get(
      "/places/countries",
      { term: request.term },
      {
        "Accept-Language": request.locale
      }
    );
  },

  getProductPrice: (searchId: string, productId: string) => {
    return http.get(
      `/customer/search/flight/requests/${searchId}/prices?${encodeURIComponent(
        "productIds[]"
      )}=${productId}`,
      {
        pricePerProductLimit: 10,
        pricePerProductOffset: 0
      }
    );
  },

  beginOrder: ({ agencyId, uuid }: { agencyId: string; uuid: string }) => {
    return http.post(`/customer/orders?agencyId=${agencyId}&uuid=${uuid}`, {});
  },

  addProductToOrder: (orderId: string, productId: string) => {
    return http.post(
      `/customer/orders/flights/${orderId}?itemId=${productId}`,
      {}
    );
  },

  getPaxType: ({ itemId, birthday }: { itemId: string; birthday: string }) => {
    const headers = {
      Accept: "application/hal+json"
    };
    return http.get(
      `/customer/orders/flights/paxType/${itemId}?birthday=${birthday}`,
      {},
      headers
    );
  },
  setMarkupToOrder: ({
    orderId,
    orderItemId,
    markup
  }: {
        orderId: string;
        orderItemId: string;
        markup: number;
    }) => {
    return http.post(
      `/customer/orders/${orderId}/${orderItemId}/markup/manual`,
      {
        manualMarkupAmount: markup
      }
    );
  },
  updateMarkupToOrder: ({
    orderId,
    orderItemId,
    markup,
    uuid
  }: {
        orderId: string;
        orderItemId: string;
        markup: number;
        uuid: string;
    }) => {
    return http.put(
      `/customer/orders/${orderId}/${orderItemId}/markup/manual/update?uuid=${uuid}`,
      {
        manualMarkupAmount: markup
      }
    );
  },

  registrationNewCustomer: (orderId: string, customer: ICustomer) => {
    return http.post(`/customer/orders/${orderId}/customers`, { ...customer });
  },

  updateCustomerInOrder: (orderId: string, customer: ICustomer) => {
    return http.post(
      `/customer/orders/${orderId}/customers/${customer.customerId}`,
      { ...customer }
    );
  },
  removeCustomerFromOrder: (orderId: string, customerId: string) => {
    return http.delete(`customer/orders/${orderId}/passengers/${customerId}`, {});
  },

  addCustomerToOrder: (
    orderId: string,
    itemId: string,
    customerId: string,
    isOwner: boolean
  ) => {
    if (isOwner) {
      return http.put(
        `customer/orders/${orderId}/contact_person?customerId=${customerId}`,
        {}
      );
    } else {
      return http.post(
        `/customer/orders/${orderId}/${itemId}/passengers?customerId=${customerId}`,
        {}
      );
    }
  },

  getSpecialAssistance: (
    orderId: string,
    itemId: string,
    customerId: string,
    type: string
  ) => {
    return http.get(
      `customer/orders/flights/${orderId}/${itemId}/${customerId}/supported/${type}`,
      {}
    );
  },

  serviceFlightDetails: (
    orderId: string,
    itemId: string,
    customerId: string,
    details: FlightPaxDetails
  ) => {
    return http.post(
      `customer/orders/flights/${orderId}/${itemId}/${customerId}/details`,
      details
    );
  },

  setSpecialAssistance: (
    orderId: string,
    itemId: string,
    customerId: string,
    customerAssistance: {
            meal?: string;
            specialAssistance?: string;
            frequentFlyerCards?: IFrequentFlyer[];
        }
  ) => {
    return http.post(
      `customer/orders/flights/${orderId}/${itemId}/${customerId}/details`,
      { ...customerAssistance }
    );
  },

  startOrderReservation: (orderId: string, onlinePayment: boolean) => {
    return http.post(`customer/orders/${orderId}/reservations`, {
      onlinePayment
    });
  },

  getAvailableBalance: (uuid: string) => {
    return http.get(
      `/agency/orders/terminal/myagency/balance?uuid=${uuid}`
    );
  },

  getReservationStatus: (orderId: string) => {
    return http.get(`customer/orders/${orderId}/reservation_status`, {});
  },

  getConfirmationStatus: (orderId: string) => {
    return http.get(`customer/orders/${orderId}/confirmation_status`, {});
  },

  startOrderPreReservation: (orderId: string) => {
    return http.get(`customer/orders/${orderId}/pre_reservation_status`, {});
  },

  getAgencyCustomers: (uuid: string, name: string) => {
    return http.put(`/agent/customers/filter?uuid=${uuid}`, { name });
  },

  getFlightOrderItems: (orderId: string) => {
    return http.get(`customer/orders/flights/${orderId}/items`, {});
  },
  getOwnUser: (uuid: string) => {
    return http.get("/mycompany/me", { uuid });
  },
  getSeatSegments: ({
    orderId,
    itemId,
    option,
    locale
  }: {
        orderId: string;
        itemId: string;
        option: string;
        locale?: string;
    }) =>
    http.get(
      `customer/orders/flights/${orderId}/${itemId}/${option}`,
      {},
      {
        "Accept-Language": locale || "en-US"
      }
    ),

  serviceReservation: (
    orderId: string,
    itemId: string,
    customerId: string,
    query: any
  ) => {
    return http.post(
      `customer/orders/flights/${orderId}/${itemId}/${customerId}/details`,
      query
    );
  },

  travelOrderResource: async(orderId: string) => {
    return http.get(`customer/orders/${orderId}`, {});
  },

  sendOrderPdf: ({
    documentFileName,
    orderId,
    locale,
    isMail = true,
    mailsTo,
    includePrice = true,
    body,
    subject,
    type,
    pdfTitle,
    uuid
  }: {
        documentFileName: string;
        orderId: string;
        locale: string;
        isMail: boolean;
        body: string;
        subject: string;
        mailsTo: string | string[];
        includePrice: boolean;
        type: string;
        pdfTitle: string
        uuid: string;
    }) =>
    http.postPdf("/pdf/documents/itinerary/", {
      companyType: "AGENCY",
      documentFileName,
      orderId,
      locale,
      body,
      subject,
      isMail,
      mailsTo,
      includePrice,
      type,
      pdfTitle,
      uuid
    }),

  backofficeIssueTickets: ({
    uuid,
    agencyId,
    orderId,
    orderItemId
  }: {
        uuid: string;
        agencyId: string;
        orderId: string;
        orderItemId: string;
    }) =>
    http.post(
      `/backoffice/agencies/${agencyId}/orders/${orderId}/items/${orderItemId}/tickets?uuid=${uuid}`,
      {}
    ),

  agentIssueTickets: ({
    uuid,
    orderId,
    orderItemId
  }: {
        uuid: string;
        orderId: string;
        orderItemId: string;
    }) =>
    http.post(
      `/agent/orders/${orderId}/items/${orderItemId}/tickets?uuid=${uuid}`,
      {}
    ),

  ticketingAllowance: ({
    uuid,
    orderId,
    orderItemId
  }: {
        uuid: string;
        orderId: string;
        orderItemId: string;
    }) =>
    http.get(
      `/agent/orders/${orderId}/items/${orderItemId}/tickets/allowed?uuid=${uuid}`,
      {}
    ),

  setRemarks: (params: {
        orderItemId: string;
        remark1: string;
        remark2: string;
        uuid: string;
    }) => {
    const { orderItemId, remark1, remark2, uuid } = params;
    const data = { orderItemId, remark1, remark2 };

    return http.put("/agency/orders/terminal/remarks?uuid=" + uuid, data);
  }
});
