import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";

import api from "./fly-location.api";
import { Airport, City } from "./types/fly-location.class";
import { IAirportPayload, IFlyLocationState } from "./types/fly-location.types";

import actionHelper from "utils/actionHelper";
import { RootState } from "../../applications/desktop/store";

const FIND_AIRPORT = actionHelper("FIND_AIRPORT");
const FIND_CITY = actionHelper("FIND_CITY");
const FIND_ALL_AIRPORTS = actionHelper("FIND_ALL_AIRPORTS");
const UPDATE_LOCATIONS = "UPDATE_LOCATIONS";

const namespaced = true;

const state: IFlyLocationState = {
  airports: new Map<string, Airport[]>(),
  cities: [new City()],
  isError: false,
  isLoadingPending: false,
  isAllAirportsPending: false
};

const getters: GetterTree<IFlyLocationState, RootState> = {
  airports: (state: IFlyLocationState): Map<string, Airport[]> =>
    state.airports,
  cities: (state: IFlyLocationState): City[] => state.cities,
  isLoadingPending: (state: IFlyLocationState): boolean =>
    state.isLoadingPending,
  isAllAirportsPending: (state: IFlyLocationState): boolean =>
    state.isAllAirportsPending
};

const actions: ActionTree<IFlyLocationState,
    RootState> = {
      async findAirports(
        { commit }: ActionContext<IFlyLocationState, RootState>,
        payload: any
      ) {
        commit(FIND_AIRPORT.INITIAL);

        try {
          const airports = await api.findAirports({
            locale: payload.locale,
            term: payload.term
          });
          commit(FIND_AIRPORT.SUCCEEDED, {
            airports,
            index: payload.index
          });
          return airports;
        } catch (e) {
          commit(FIND_AIRPORT.FAILED, e);
        }
      },
      async findAirportsByIP(
        { commit }: ActionContext<IFlyLocationState, RootState>,
        locale: string
      ) {
        commit(FIND_AIRPORT.INITIAL);
        try {
          commit(FIND_AIRPORT.SUCCEEDED, {
            airports: await api.findAirportsByIP(locale),
            index: "00"
          });
        } catch (e) {
          commit(FIND_AIRPORT.FAILED, e);
        }
      },
      async findAllAirports({
        commit,
        dispatch
      }: ActionContext<IFlyLocationState, RootState>, payloads: IAirportPayload[]) {
        commit(FIND_ALL_AIRPORTS.INITIAL);
        await new Promise(resolve => setTimeout(resolve, 1000));
        try {
          const promises = payloads.map(payload => dispatch("findAirports", payload));
          await Promise.all(promises);
          commit(FIND_ALL_AIRPORTS.SUCCEEDED);
        } catch (e) {
          commit(FIND_ALL_AIRPORTS.FAILED, e);
        }
      },
      findCities(
        {
          state,
          commit
        }: ActionContext<IFlyLocationState, RootState>,
        index: string
      ) {
        if (state.airports.get(index)) {
          return false;
        }
        commit(FIND_CITY.INITIAL);

        const cities = [
          {
            city: {
              code: "SYD",
              country: {
                code: "AU",
                locale: "EN",
                name: "Australia"
              },
              name: "Sydney"
            },
            code: "SYD",
            locale: "EN",
            name: "Kinsford Smith"
          },
          {
            city: {
              code: "SYD",
              country: {
                code: "AU",
                locale: "EN",
                name: "Australia"
              },
              name: "Sydney"
            },
            code: "BWU",
            locale: "EN",
            name: "Sydney Bankstown"
          },
          {
            city: {
              code: "SYD",
              country: {
                code: "AU",
                locale: "EN",
                name: "Australia"
              },
              name: "Sydney"
            },
            code: "LBH",
            locale: "EN",
            name: "Palm Beach SPB"
          },
          {
            city: {
              code: "SYD",
              country: {
                code: "AU",
                locale: "EN",
                name: "Australia"
              },
              name: "Sydney"
            },
            code: "RSE",
            locale: "EN",
            name: "Rose Bay"
          },
          {
            city: {
              code: "SYD",
              country: {
                code: "AU",
                locale: "EN",
                name: "Australia"
              },
              name: "Sydney"
            },
            code: "XYS",
            locale: "EN",
            name: "Sydney Ferry Port"
          }
        ];

        try {
          setTimeout(() => commit(FIND_CITY.SUCCEEDED, {
            index,
            cities
          }), 0);
        } catch (e) {
          commit(FIND_CITY.FAILED, e);
        }
        return cities;
      }
    };

// TODO: fixed mutations in AirportStore
const mutations: MutationTree<IFlyLocationState> = {
  [UPDATE_LOCATIONS](state, airports) {
    state.airports = airports;
  },
  [FIND_AIRPORT.INITIAL](state) {
    state.isLoadingPending = true;
  },
  [FIND_AIRPORT.SUCCEEDED](state, {
    airports,
    index
  }) {
    state.airports.set(index, airports.data);
    state.isLoadingPending = false;
  },
  [FIND_AIRPORT.FAILED](error) {
    // console.log("FAILED AIRPORT REQUEST", error)
  },
  [FIND_CITY.INITIAL](state) {
    state.isLoadingPending = true;
  },
  [FIND_CITY.SUCCEEDED](state, {
    index,
    cities
  }) {
    state.airports.set(index, cities);
    state.isLoadingPending = false;
  },
  [FIND_CITY.FAILED](error) {
    // console.log("FAILED CITY REQUEST", error)
  },
  [FIND_ALL_AIRPORTS.INITIAL](state) {
    state.isAllAirportsPending = true;
  },
  [FIND_ALL_AIRPORTS.SUCCEEDED](state) {
    state.isAllAirportsPending = false;
  },
  [FIND_ALL_AIRPORTS.FAILED](state, error) {
    state.isAllAirportsPending = false;
    console.error(error);
  }
};

export const flyLocationStore: Module<IFlyLocationState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};
