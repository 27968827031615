import {
  IPackage,
  IPackageClass,
  IPackageProduct,
  IPackageProductClass,
  IPackageProductPrice,
  IPackageProductsListClass,
  IPackagesListClass,
  Package,
  PackageProduct
} from "modules/product-result/data/product-result.types";
import VueI18n from "../../../../../applications/desktop/i18n";

export default class PackageProductsList implements IPackageProductsListClass {
  private _initialPackageProductsList: IPackageProductClass[] = [];
  private _packageProductsList: IPackageProductClass[] = [];
  private _productsIds: IPackageProductsListClass["productsIds"] = [];
  private _productsFareBasisList: string[] = [];
  private _productsProvidersList: string[] = [];
  private _notLocalizeFareBasisList: string[] = [];
  // temporary
  private _prices: IPackageProductPrice[] = [];

  constructor(private _packageProductsListData: IPackageProduct[]) {
    this._packageProductsList = this._packageProductsListData.map(product => {
      this._productsIds.push(product.productId);
      this.pushToNotLocalizeFareBasisList(product.fareBasis);
      this.pushToProductsFareBasisList(product.fareBasis);
      this.pushToProductsProvidersList(product.provider.name);
      return new PackageProduct(product);
    });
    this._initialPackageProductsList = this._packageProductsList;
  }

  get content(): IPackageProductsListClass["content"] {
    return this._packageProductsList;
  }

  get initialContent(): IPackageProductsListClass["initialContent"] {
    return this._initialPackageProductsList;
  }

  get productsIds(): IPackageProductsListClass["productsIds"] {
    return this._productsIds;
  }

  get pricesList(): IPackageProductPrice[] {
    return this._prices;
  }

  get productsFareBasis(): string[] {
    return this._productsFareBasisList;
  }

  get notLocalizeFareBasisList(): string[] {
    return this._notLocalizeFareBasisList;
  }

  get productsProviders(): string[] {
    return this._productsProvidersList;
  }

  set pricesList(value: IPackageProductPrice[]) {
    this._prices = value;
  }

  set productsFareBasis(value: string[]) {
    this._productsFareBasisList = value;
  }

  set productsProviders(value: string[]) {
    this._productsProvidersList = value;
  }

  removeProductsWithoutDetails(): void {
    this._initialPackageProductsList = this._initialPackageProductsList.filter(product => product.details);
    this._packageProductsList = this._initialPackageProductsList.slice();
    this._productsIds = this._packageProductsList.map(p => p.content.productId);
  }

  filterProducts({
    fareBasisList,
    fastOkStatus,
    providersList
  }: {
    fareBasisList: string[]
    fastOkStatus: boolean
    providersList: string[]
  }): void {
    this._packageProductsList = this._initialPackageProductsList.filter(
      product => {
        const findedFareBasis: number = fareBasisList.indexOf(
          product.content.fareBasis
        );
        const findedProvider: number = providersList.indexOf(
          product.content.provider.name
        );
        const matchFastOk: boolean = product.isFastOk() === fastOkStatus;

        if (
          findedFareBasis !== -1 &&
          findedProvider !== -1 &&
          (matchFastOk || !fastOkStatus)
        ) {
          return product;
        }
      }
    );
  }

  getProduct(productId: string): IPackageProductClass {
    return (
      this._packageProductsList.find(
        productData => productData.content.productId === productId
      ) || null
    );
  }

  getAgenciesIds(): string[] {
    return this._packageProductsList[0].prices.map(
      price => price.agency.agencyId
    );
  }

  pushToNotLocalizeFareBasisList(fb:string): void {
    const indexDublicateFB: number = this._notLocalizeFareBasisList.indexOf(fb);

    if (indexDublicateFB === -1) {
      this._notLocalizeFareBasisList.push(fb);
    }
  }

  pushToProductsFareBasisList(fb: string): void {
    fb = `package-search.boarding.${fb}` !== (VueI18n.t(`package-search.boarding.${fb}`) as string)
      ? (VueI18n.t(`package-search.boarding.${fb}`) as string)
      : fb;
    const indexDublicateFB: number = this._productsFareBasisList.indexOf(fb);

    if (indexDublicateFB === -1) {
      this._productsFareBasisList.push(fb);
    }
  }

  pushToProductsProvidersList(fb: string): void {
    const indexDublicateFB: number = this._productsProvidersList.indexOf(fb);

    if (indexDublicateFB === -1) {
      this._productsProvidersList.push(fb);
    }
  }

  calculateMinPrice(): IPackageProductPrice[] {
    return this.getAgenciesIds().map(agencyId => {
      const prices = this._prices.filter(
        price => agencyId === price.agency.agencyId
      );
      return prices.sort((priceA, priceB) => {
        return priceA.price.total - priceB.price.total;
      })[0];
    });
  }
}
