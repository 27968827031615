import {
  IPackageProduct,
  IPackageProductClass,
  IPackageProductDetailsClass,
  IPackageProductPrice,
  IPaxComplectPrice
} from "modules/product-result/data/product-result.types";
import VueI18n from "../../../../../applications/desktop/i18n";

export default class PackageProduct implements IPackageProductClass {
  constructor(private _product: IPackageProduct) {
  }

  private _details: IPackageProductDetailsClass;

  get details(): IPackageProductDetailsClass {
    return this._details;
  }

  set details(value: IPackageProductDetailsClass) {
    this._details = value;
  }

  private _prices: IPackageProductPrice[] = [];

  get prices(): IPackageProductPrice[] {
    return this._prices;
  }

  private _paxComplectsPrices: IPaxComplectPrice[] = [];

  get paxComplectsPrices(): IPackageProductClass["paxComplectsPrices"] {
    return this._paxComplectsPrices;
  }

  set paxComplectsPrices(value: IPackageProductClass["paxComplectsPrices"]) {
    this._paxComplectsPrices = value;
  }

  private _fareBasis: IPackageProduct["fareBasis"] = "";

  get fareBasis(): IPackageProduct["fareBasis"] {
    return this._fareBasis;
  }

  get content(): IPackageProductClass["content"] {
    return this._product;
  }

  getPaxComplectPrice(complectId: string): IPaxComplectPrice {
    return (
      this._paxComplectsPrices.find(price => price.complectId === complectId) ||
      null
    );
  }

  setFareBasis(): void {
    this._fareBasis = this._product.fareBasis;
  }

  localizeBoardingType(): void {
    this._product.fareBasis =
      `package-search.boarding.${this._product.fareBasis}` !== (VueI18n.t(`package-search.boarding.${this._product.fareBasis}`) as string)
        ? (VueI18n.t(`package-search.boarding.${this._product.fareBasis}`) as string)
        : this._product.fareBasis;
  }

  addToPricesList(price: IPackageProductPrice[]): void {
    this._prices = price;
  }

  isFastOk(): boolean {
    return this._product.dealFeatures.indexOf("FAST_OK") !== -1;
  }

  isCarIncluded(): boolean {
    return this._product.dealFeatures.indexOf("CAR_INCLUDED") !== -1;
  }
}
