// TODO: this import is addiction (import must use in entry point)
import http from "common/http/createHttp";

import { IConvertedQuery, IProductFilter } from "modules/product-result/data/product-result.types";
import { HotelSupplierPriceResource } from "tl-api-doc/typescript-generator";
import { ApiBaseResource } from "../../common/types/types";

const beginSearch = (query: IConvertedQuery) => {
  return http.post("/customer/search/flight/requests", { ...query });
};

const applyResultFilter = (requestId: string, filter: IProductFilter) => {
  return http.put<any>(
    `/customer/search/statpackage/requests/${requestId}/filter`,
    {
      ...filter
    }
  );
};

const applyResultFilterForHotels = (
  requestId: string,
  filter: IProductFilter
) => {
  return http.put<any>(`/customer/search/hotels/requests/${requestId}/filter`, {
    ...filter
  });
};

const groupResultByHotel = (
  requestId: string,
  isExclude = false,
  filter?: any
) => {
  const excludePaths: string[] = [
    "hotels",
    "hotelFacilities",
    "pricesTotal",
    "pricesPerOne"
  ];
  const excludeQueries: string = isExclude
    ? `?${encodeURI("excludePaths[]")}=${excludePaths}`
    : "";
  const url = `/customer/search/statpackage/requests/${requestId}/hotels/filter${excludeQueries}`;
  return http.put(url, {
    ...filter
  });
};

// get HotelSupplierPriceResource[]
const getHotelSupplierPrices = (requestId: string, filter?: any) => {
  return http.put<ApiBaseResource<HotelSupplierPriceResource>>(
    `/customer/search/hotels/requests/${requestId}/digest/hotels`,
    {
      ...filter,
      page: 0, // Всегда хотим первую страницу и максимум результатов
      size: 10000
    }
  );
};

const groupResultByHotelForHotels = (
  requestId: string,
  isExclude = false,
  filter?: any
) => {
  const excludePaths: string[] = [
    "hotels",
    "hotelFacilities",
    "pricesTotal",
    "pricesPerOne"
  ];
  const excludeQueries: string = isExclude
    ? `?${encodeURI("excludePaths[]")}=${excludePaths}`
    : "";
  const url = `/customer/search/hotels/requests/${requestId}/hotels/filter${excludeQueries}`;
  return http.put(url, {
    ...filter
  });
};

const getAgencyPrices = (requestId: string, productIds: string[]) => {
  return http.get(
    `/customer/search/statpackage/requests/${requestId}/prices?${encodeURIComponent(
      "productIds[]"
    )}=${productIds}`,
    {
      pricePerProductLimit: 10,
      pricePerProductOffset: 0
    }
  );
};

const getAgencyPricesForHotels = (requestId: string, productIds: string[]) => {
  return http.get(
    `/customer/search/hotels/requests/${requestId}/prices?${encodeURIComponent(
      "productIds[]"
    )}=${productIds}`,
    {
      pricePerProductLimit: 10,
      pricePerProductOffset: 0
    }
  );
};

const getHotelReviews = (hotelIds: string[]) => {
  return http.get(
    `/customer/search/hotels/reviews?${encodeURIComponent(
      "hotelIds[]"
    )}=${hotelIds}`,
    {}
  );
};

const getProductDetails = (productId: string, filter?: any) => {
  return http.get(
    `/customer/search/statpackage/products/${productId}/details`,
    {
      ...filter
    }
  );
};

const getProductDetailsForHotels = (productId: string, filter?: any) => {
  return http.get(`/customer/search/hotels/products/${productId}/details`, {
    ...filter
  });
};

const getPaxComplectAgencyPrices = ({
  productId,
  agencyId,
  complectIds,
  filter
}: {
    productId: string;
    agencyId: string;
    complectIds: string[];
    filter?: any;
}) => {
  return http.get(
    `/customer/search/statpackage/products/${productId}/details/prices?agencyId=${agencyId}&${encodeURIComponent(
      "complectIds[]"
    )}=${complectIds}`,
    {}
  );
};

const getPaxComplectAgencyPricesForHotels = ({
  productId,
  agencyId,
  complectIds,
  filter
}: {
    productId: string;
    agencyId: string;
    complectIds: string[];
    filter?: any;
}) => {
  return http.get(
    `/customer/search/hotels/products/${productId}/details/prices?agencyId=${agencyId}&${encodeURIComponent(
      "complectIds[]"
    )}=${complectIds}`,
    {}
  );
};

const setFacilitiesWithoutIcons = ({ icons }: { icons: string }) =>
  http.postPdf("/pdf/facilities/icons/without", { icons });

export default {
  getHotelSupplierPrices,
  applyResultFilter,
  applyResultFilterForHotels,
  beginSearch,
  getAgencyPrices,
  getAgencyPricesForHotels,
  getHotelReviews,
  getPaxComplectAgencyPrices,
  getPaxComplectAgencyPricesForHotels,
  getProductDetails,
  getProductDetailsForHotels,
  groupResultByHotel,
  groupResultByHotelForHotels,
  setFacilitiesWithoutIcons
};
