import actionHelper from "utils/actionHelper";
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";

import {
  IPackage,
  IPackageClass,
  IPackageProductDetailsClass,
  IPackageResponse,
  IPackageResultState,
  IPackagesListClass,
  IPaxComplectPrice,
  IProductFilterClass,
  Package,
  PackageProductDetails,
  PackagesList,
  ProductFilter,
  SearchStatusTypes
} from "modules/product-result/data/product-result.types";

import api from "modules/product-result/product-result.api";
import { QueryTypes } from "modules/product-search/data/product-search.consts";
import { ApiBaseResource, ApiBaseSataPackageResource } from "common/types/types";
import {
  HotelFilter,
  HotelSupplierPriceResource,
  PaxComplectsResource,
  StatPackageFilter,
  StatPackagePreReservationDetailsResource
} from "tl-api-doc/typescript-generator";
import { poll } from "common/polling";
import _ from "lodash";
import { RootState } from "applications/desktop/store";

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
const namespaced = true;

const RUN_PRODUCTS_SEARCH = actionHelper("RUN_PRODUCTS_SEARCH");
const GET_GROUPED_RESULTS_BY_HOTEL = actionHelper("GET_GROUPED_RESULTS_BY_HOTEL");
const GET_PRODUCT_DETAILS = actionHelper("GET_PRODUCT_DETAILS");
const GET_AGENCY_PRICES = actionHelper("GET_AGENCY_PRICES");
const SET_PRODUCTS_DETAILS = actionHelper("SET_PRODUCTS_DETAILS");
const CREATE_PACKAGE_CLASS = actionHelper("CREATE_PACKAGE_CLASS");
const GET_PRODUCT_DETAILS_PRICES = actionHelper("GET_PRODUCT_DETAILS_PRICES");
const APPLY_FILTER = actionHelper("APPLY_FILTER");
const SET_PACKAGE_DETAILS = actionHelper("SET_PACKAGE_DETAILS");
const SET_HOTEL_REVIEW = actionHelper("SET_HOTEL_REVIEW");
const SET_FACILITIES_WITHOUT_ICONS = actionHelper("SET_FACILITIES_WITHOUT_ICONS");

const GET_PACKAGE_RESULT_FOR_DIGEST = actionHelper("GET_PACKAGE_RESULT_FOR_DIGEST");

const RESET_FILTER = "RESET_FILTER";
const THIS_FILTER_UNUSED = "THIS_FILTER_UNUSED";
const SET_FILTER = "SET_FILTER";
const SET_DETAILS_ERROR = "SET_DETAILS_ERROR";

const state: IPackageResultState = {
  queryBody: null,
  orderId: null,
  requestId: null,
  digest: null,
  searchIntervalId: null,
  currentPackage: null,
  filter: null,
  initialFilter: null,
  isError: false,
  isFilterChanged: false,
  isInitPackagePending: true,
  isLoadingPending: false,
  isPricesPending: false,
  isDetailsPending: false,
  isDetailsError: false,
  packageResponse: null,
  stoppingPolling: false,
  packagesList: null,
  details: [],
  agentPriceList: [],
  isOrderStartPending: false,
  packageDetailsForMap: null,
  isPackageDetailsForMapPending: false,
  hotelReviews: null,
  isHotelReviewsPending: false,

  digestForHotels: null
};

const getters: GetterTree<IPackageResultState, RootState> = {
  digestForHotels: (state: IPackageResultState) => state.digestForHotels,
  queryBody: (state: IPackageResultState) => state.queryBody,
  searchIntervalId: (state: IPackageResultState) => state.searchIntervalId,
  currentPackage: (state: IPackageResultState) => state.currentPackage,
  digest: (state: IPackageResultState) => state.digest,
  filter: (state: IPackageResultState) => state.filter,
  requestId: (state: IPackageResultState) => state.requestId,
  isDetailsPending: (state: IPackageResultState) => state.isDetailsPending,
  isDetailsError: (state: IPackageResultState) => state.isDetailsError,
  initialFilter: (state: IPackageResultState) => state.initialFilter,
  isError: (state: IPackageResultState) => state.isError,
  isFilterChanged: (state: IPackageResultState) => state.isFilterChanged,
  isInitPackagePending: (state: IPackageResultState) =>
    state.isInitPackagePending,
  isOrderStartPending: (state: IPackageResultState) =>
    state.isOrderStartPending,
  stoppingPolling: (state: IPackageResultState) => state.stoppingPolling,
  isLoadingPending: (state: IPackageResultState) => state.isLoadingPending,
  isPricesPending: (state: IPackageResultState) => state.isPricesPending,
  packageResponse: (state: IPackageResultState) => state.packageResponse,
  isSearchEnd: (state: IPackageResultState) =>
    state.packageResponse &&
        state.packageResponse.page &&
        state.packageResponse.page.query &&
        state.packageResponse.page.query.searchStatus ===
        SearchStatusTypes.DEALS_POST_PROCESSED,
  packagesList: (state: IPackageResultState) => state.packagesList,
  details: (state: IPackageResultState) => state.details,
  orderId: (state: IPackageResultState) => state.orderId,
  packageDetailsForMap: (state: IPackageResultState) => state.packageDetailsForMap,
  isPackageDetailsForMapPending: (state: IPackageResultState) => state.isPackageDetailsForMapPending,
  hotelReviews: (state: IPackageResultState) => state.hotelReviews,
  isHotelReviewsPending: (state: IPackageResultState) => state.isHotelReviewsPending,
  isMultiRoom: (state: IPackageResultState) => Boolean(state.queryBody?.rooms?.length > 1)
};

const actions: ActionTree<IPackageResultState,
    RootState> = {
      async getHotelReviews({ commit }: ActionContext<IPackageResultState, RootState>, { hotelIds }: { hotelIds: string[] }) {
        commit(SET_HOTEL_REVIEW.INITIAL);
        try {
          const result = (await api.getHotelReviews(hotelIds)).data;
          commit(SET_HOTEL_REVIEW.SUCCEEDED, result || null); // to avoid empty string here
        } catch (error) {
          commit(SET_HOTEL_REVIEW.FAILED, error);
        }
      },

      async getPackageDetailsForMap({ commit }: ActionContext<IPackageResultState, RootState>, {
        filter,
        requestId,
        queryType = QueryTypes.package
      }) {
        commit(SET_PACKAGE_DETAILS.INITIAL);
        try {
          const getDetails = queryType === QueryTypes.hotel
            ? api.groupResultByHotelForHotels
            : api.groupResultByHotel;
          const details = (await getDetails(requestId, true, filter)).data.content[0];
          commit(SET_PACKAGE_DETAILS.SUCCEEDED, details);
        } catch (error) {
          commit(SET_PACKAGE_DETAILS.FAILED, error);
        }
      },
      async pulling({
        commit,
        getters,
        dispatch,
        rootGetters,
        state
      }: ActionContext<IPackageResultState, RootState>,
      {
        requestId,
        queryType
      }: { requestId: string; queryType?: QueryTypes }) {
        async function gettingRequest(): Promise<ApiBaseSataPackageResource> {
          return dispatch(
            "getGroupedResultsByHotel",
            {
              isExclude: true,
              requestId,
              loadMore: false,
              queryType
            }
          );
        }

        function validation(value: ApiBaseSataPackageResource): boolean {
          console.log("value", value);
          if (getters.isSearchEnd) {
            return true;
          }
          return value.page.query.searchStatus === SearchStatusTypes.DEALS_POST_PROCESSED;
        }

        function stoppingFn(): boolean {
          return requestId !== getters.requestId || getters.stoppingPolling;
        }

        return poll(gettingRequest, validation, 20, 10000, stoppingFn);
      },

      async runPackageSearch(
        {
          commit,
          getters,
          dispatch,
          rootGetters
        }: ActionContext<IPackageResultState, RootState>,
        {
          requestId,
          queryType
        }: { requestId: string; queryType?: QueryTypes }
      ): Promise<ApiBaseSataPackageResource> {
        try {
          commit(RUN_PRODUCTS_SEARCH.INITIAL, requestId);
          const val = await dispatch("pulling", {
            requestId,
            queryType
          });
          await dispatch("applyFilter", {
            requestId,
            isNewSearch: false,
            loadMore: false,
            queryType
          });
          return val;
        } catch (error) {
          console.error(error);
          commit(RUN_PRODUCTS_SEARCH.FAILED, error);
          return Promise.reject(error);
        }
      },

      // get resultFor digest
      async getPackageResultForDigest(
        { commit, getters }: ActionContext<IPackageResultState, RootState>,
        { requestId, queryType }: { requestId: string; queryType?: QueryTypes }
      ): Promise<ApiBaseResource<HotelSupplierPriceResource>> {
        commit(GET_PACKAGE_RESULT_FOR_DIGEST.INITIAL);

        try {
          const filter = getters.filter
            ? queryType === QueryTypes.hotel
              ? (getters.filter as IProductFilterClass).convertFilterToPackageResultForHotels()
              : (getters.filter as IProductFilterClass).convertFilterToPackageResult()
            : null;

          // TODO: add api for queryType === QueryTypes.package

          if (queryType === QueryTypes.package) {
            // const e = new Error("Not implemented");
            commit(GET_PACKAGE_RESULT_FOR_DIGEST.FAILED, "Not implemented");
            return Promise.resolve();
          }
          const request = (await api.getHotelSupplierPrices(requestId, filter)).data;

          commit(GET_PACKAGE_RESULT_FOR_DIGEST.SUCCEEDED, { digestForHotels: request.content });

          return request;
        } catch (e) {
          commit(GET_PACKAGE_RESULT_FOR_DIGEST.FAILED, e);
          return Promise.reject(e);
        }
      },
      async getProductFromTab({ commit }, {
        productId,
        searchId,
        queryType = QueryTypes.package
      }: {
        productId: string,
        searchId: string,
        queryType?: QueryTypes
    }): Promise<{
        packageClass: Package
    }> {
        try {
          commit(CREATE_PACKAGE_CLASS.INITIAL);
          const packageFilterFn = queryType === QueryTypes.hotel
            ? api.groupResultByHotelForHotels
            : api.groupResultByHotel;
          const products: IPackage[] = ((await packageFilterFn(
            searchId,
            false,
            { productIds: [productId] }
          )).data as IPackageResponse).content;

          // тут ждем только 1 продукт, но в пакете их может быть много

          const product = products.find(product => product.products.some(product => product.productId === productId));

          const packageClass: Package = new Package(product);

          // получаем цены
          const priceFn = queryType === QueryTypes.hotel ? api.getAgencyPricesForHotels : api.getAgencyPrices;
          const prices: any[] = (await priceFn(searchId, packageClass.products.productsIds)).data;

          packageClass.products.pricesList = prices;

          packageClass.products.productsIds.forEach((id) => {
            const productClass = packageClass.products
              .getProduct(id);

            productClass.addToPricesList(prices.filter(p => p.productId === id));
          });

          // получаем детали
          const detailsFn = queryType === QueryTypes.hotel ? api.getProductDetailsForHotels : api.getProductDetails;

          await Promise.all(packageClass.products.productsIds.map(async(id) => {
            const detailsResponse = await detailsFn(id);
            const detailsData: StatPackagePreReservationDetailsResource = detailsResponse?.data;

            const paxComplectsDetailsData: PaxComplectsResource = detailsData.paxComplectsResource;
            const details = new PackageProductDetails(paxComplectsDetailsData);

            packageClass.products.getProduct(id).details = details;
            return details;
          }));
          commit(CREATE_PACKAGE_CLASS.SUCCEEDED, packageClass);
          return { packageClass };
        } catch (e) {
          commit(CREATE_PACKAGE_CLASS.FAILED, e);
        }
      },
      async getGroupedResultsByHotel(
        {
          commit,
          state,
          getters
        }: ActionContext<IPackageResultState, RootState>,
        {
          isExclude,
          isNewSearch = false,
          requestId,
          loadMore,
          queryType = QueryTypes.package
        }: { isExclude: boolean, isNewSearch: boolean; requestId: string; loadMore?: boolean, queryType?: QueryTypes }
      ) {
        const filter = getters.filter
          ? queryType === QueryTypes.hotel
            ? (getters.filter as IProductFilterClass).convertFilterToPackageResultForHotels()
            : (getters.filter as IProductFilterClass).convertFilterToPackageResult()
          : null;

        if (loadMore && filter?.page >= (getters.packageResponse as ApiBaseSataPackageResource).page.totalPages) {
          return Promise.resolve();
        }
        try {
          commit(GET_GROUPED_RESULTS_BY_HOTEL.INITIAL);

          const fn = queryType === QueryTypes.hotel ? api.groupResultByHotelForHotels : api.groupResultByHotel;
          const packageResponse: IPackageResponse = (await fn(requestId, isExclude, filter)).data;
          const storedPackagesList: IPackagesListClass = state.packagesList;
          let packagesList: IPackagesListClass = null;

          if (isNewSearch) {
            packagesList = new PackagesList(packageResponse.content);
            commit(GET_GROUPED_RESULTS_BY_HOTEL.SUCCEEDED, {
              packageResponse,
              packagesList
            });
            return Promise.resolve(packagesList);
          }
          if (loadMore) {
            storedPackagesList.concatPackages(packageResponse.content);
            packagesList = storedPackagesList;
          } else {
            packagesList = new PackagesList(packageResponse.content);
          }

          commit(GET_GROUPED_RESULTS_BY_HOTEL.SUCCEEDED, {
            packageResponse,
            packagesList
          });
          return Promise.resolve(packagesList);
        } catch (error) {
          console.log("error", error);
          commit(GET_GROUPED_RESULTS_BY_HOTEL.FAILED, error);
        }
      },

      async getAgenciesPrices(
        {
          commit,
          dispatch
        },
        {
          requestId,
          packagesList,
          queryType
        }: { requestId: string; packagesList: IPackagesListClass, queryType: QueryTypes }
      ) {
        try {
          commit(GET_AGENCY_PRICES.INITIAL);
          const fn = queryType === QueryTypes.hotel ? api.getAgencyPricesForHotels : api.getAgencyPrices;
          const filtered = packagesList.content.filter(pck => pck.products.pricesList.length === 0);
          await Promise.all(filtered.map(async resPackage => {
            const prices: any[] = (await fn(requestId, resPackage.products.productsIds)).data;

            // temp
            resPackage.products.pricesList = prices;

            resPackage.products.productsIds.forEach((id) => {
              resPackage.products
                .getProduct(id)
                .addToPricesList(prices.filter(p => p.productId === id));
            });
          })
          );
          commit(GET_AGENCY_PRICES.SUCCEEDED);
          return Promise.resolve();
        } catch (error) {
          commit(GET_AGENCY_PRICES.FAILED, error);
        }
      },

      async getSoloAgenciesPrices(
        {
          commit,
          dispatch
        },
        {
          requestId,
          pack,
          queryType
        }: { requestId: string; pack: IPackageClass, queryType: QueryTypes }
      ) {
        try {
          const fn = queryType === QueryTypes.hotel ? api.getAgencyPricesForHotels : api.getAgencyPrices;
          const prices: any[] = (await fn(requestId, pack.products.productsIds)).data;

          // temp
          pack.products.pricesList = prices;

          pack.products.productsIds.forEach((id) => {
            pack.products
              .getProduct(id)
              .addToPricesList(prices.filter(p => p.productId === id));
          });
          return Promise.resolve();
        } catch (error) {
          commit(GET_AGENCY_PRICES.FAILED, error);
        }
      },

      async applyFilter(
        {
          commit,
          getters,
          dispatch
        },
        {
          requestId,
          isNewSearch = false,
          loadMore = false,
          queryType = QueryTypes.package
        }: { isNewSearch: boolean; requestId: string; loadMore: boolean, queryType: QueryTypes }
      ) {
        try {
          if (!loadMore && !!getters.filter && !!getters.filter.page) {
            getters.filter.page = 0;
          }
          const packagesList: IPackagesListClass = await dispatch(
            "getGroupedResultsByHotel",
            {
              isExclude: false,
              requestId,
              loadMore,
              queryType,
              isNewSearch
            }
          );
          dispatch("getPackageResultForDigest", { requestId, queryType });
          await dispatch("getAgenciesPrices", { requestId, packagesList, queryType });
          commit(APPLY_FILTER.SUCCEEDED);
          return Promise.resolve();
        } catch (error) {
          commit(APPLY_FILTER.FAILED, error);
        }
      },

      async setProductsDetails(
        {
          commit,
          dispatch
        },
        {
          resPackage,
          queryType = QueryTypes.package
        }: { resPackage: IPackageClass, queryType: QueryTypes }
      ) {
        resPackage.setDetailsPending(true);

        const getDetails = resPackage.products.productsIds.map(async productId => {
          const detailsData: PaxComplectsResource = await dispatch("getProductDetails", {
            productId,
            queryType
          });
          const details = new PackageProductDetails(detailsData);
          commit(SET_PRODUCTS_DETAILS.SUCCEEDED, {
            details,
            packageRes: resPackage,
            productId
          });
        });

        return Promise.all(getDetails).catch(error => {
          commit(SET_PRODUCTS_DETAILS.FAILED, {
            error,
            packageRes: resPackage
          });
        });
      },

      async getProductDetails(
        {
          commit,
          dispatch
        },
        {
          productId,
          queryType = QueryTypes.package
        }: { productId: string, queryType: QueryTypes }
      ): Promise<PaxComplectsResource> {
        try {
          const fn = queryType === QueryTypes.hotel ? api.getProductDetailsForHotels : api.getProductDetails;
          const detailsResponse = await fn(productId);
          const detailsData: StatPackagePreReservationDetailsResource = detailsResponse?.data;

          if (detailsData.status === "AWAIT") {
            await delay(1000);
            return dispatch("getProductDetails", {
              productId,
              queryType
            });
          } else if (detailsData.status === "ERROR") {
            throw new Error("Get details error");
          } else {
            commit(GET_PRODUCT_DETAILS.SUCCEEDED, detailsData);
            return detailsData.paxComplectsResource;
          }
        } catch (error) {
          commit(GET_PRODUCT_DETAILS.FAILED, error);
        }
      },

      async getPaxComplectsPrices(
        { commit },
        {
          resPackage,
          agencyId,
          queryType = QueryTypes.package
        }: {
            resPackage: IPackageClass,
            agencyId: string,
            queryType: QueryTypes
        }
      ) {
        try {
          commit(GET_PRODUCT_DETAILS_PRICES.INITIAL);
          return Promise.all(
            resPackage.products.content.map(async product => {
              const fn = queryType === QueryTypes.hotel
                ? api.getPaxComplectAgencyPricesForHotels
                : api.getPaxComplectAgencyPrices;
              const complectsChunks = _.chunk(product.details.complectsIds, 20);
              const resultPromises = complectsChunks.map((complects) => {
                return {
                  agencyId,
                  complectIds: complects,
                  productId: product.content.productId
                };
              }).map(sendingData => fn(sendingData));
              const resultPromise = await Promise.all(resultPromises);
              const paxPrices = resultPromise.map((axiosData) => axiosData.data).flat();

              // const paxPrices = (await api.getPaxComplectAgencyPrices({
              //   agencyId,
              //   complectIds: product.details.complectsIds,
              //   productId: product.content.productId
              // })).data

              commit(GET_PRODUCT_DETAILS_PRICES.SUCCEEDED, { paxPrices });
            })
          );
        } catch (error) {
          commit(GET_PRODUCT_DETAILS_PRICES.FAILED, error);
        }
      },

      async initPackage({
        commit,
        state
      }, { packageContent }: { packageContent: any }) {
        try {
          commit(CREATE_PACKAGE_CLASS.INITIAL);

          const packageClass = new Package(packageContent.content);

          packageClass.products.pricesList = packageContent.prices;
          packageClass.products.productsIds.forEach((id) => {
            const product = packageClass.products.getProduct(id);
            const details = packageContent.details.find((det: any) => det[0] === id)[1];
            product.addToPricesList(
              packageContent.prices.filter((p: any) => p.productId === id)
            );

            product.details = details
              ? new PackageProductDetails(details)
              : null;
          });
          packageClass.products.removeProductsWithoutDetails();

          commit(CREATE_PACKAGE_CLASS.SUCCEEDED, packageClass);

          return Promise.resolve();
        } catch (error) {
          commit(CREATE_PACKAGE_CLASS.FAILED, error);
        }
      },

      async setDetailsError({ commit }, isDetailsError) {
        commit(SET_DETAILS_ERROR, isDetailsError);
      },

      async setFacilitiesWithoutIcons({ commit }, { icons }) {
        commit(SET_FACILITIES_WITHOUT_ICONS.INITIAL);
        try {
          await api.setFacilitiesWithoutIcons({ icons });
          commit(SET_FACILITIES_WITHOUT_ICONS.SUCCEEDED);
        } catch (error) {
          commit(SET_FACILITIES_WITHOUT_ICONS.FAILED, error);
        }
      }
    };

const mutations: MutationTree<IPackageResultState> = {
  [SET_HOTEL_REVIEW.INITIAL](state: IPackageResultState) {
    state.isHotelReviewsPending = true;
  },
  [SET_HOTEL_REVIEW.FAILED](state: IPackageResultState, error) {
    state.isHotelReviewsPending = false;
    throw new Error(`getHotelReviews error: ${error}`);
  },
  [SET_HOTEL_REVIEW.SUCCEEDED](state: IPackageResultState, result) {
    state.hotelReviews = result;
    state.isHotelReviewsPending = false;
  },

  [SET_PACKAGE_DETAILS.INITIAL](state: IPackageResultState) {
    state.isPackageDetailsForMapPending = true;
  },
  [SET_PACKAGE_DETAILS.FAILED](state: IPackageResultState, error) {
    state.isPackageDetailsForMapPending = false;
    throw new Error(`getting package details process is error: ${error}`);
  },
  [SET_PACKAGE_DETAILS.SUCCEEDED](state: IPackageResultState, packageDetails) {
    state.packageDetailsForMap = packageDetails;
    state.isPackageDetailsForMapPending = false;
  },
  [RUN_PRODUCTS_SEARCH.INITIAL](state, requestId) {
    state.stoppingPolling = false;
    state.digestForHotels = [];
    state.packagesList = null;
    state.filter = null;
    state.initialFilter = null;
    state.requestId = requestId;
  },
  [RUN_PRODUCTS_SEARCH.FAILED](state, error) {
    state.packagesList = null;
    console.error("RUN_PRODUCTS_SEARCH.FAILED error:", error);
    throw new Error(`RUN_PRODUCTS_SEARCH.FAILED error: ${error}`);
  },
  [GET_GROUPED_RESULTS_BY_HOTEL.INITIAL](state) {
    state.isLoadingPending = true;
    state.stoppingPolling = false;
  },
  [GET_GROUPED_RESULTS_BY_HOTEL.SUCCEEDED](
    state: IPackageResultState,
    {
      packageResponse,
      packagesList
    }: { packageResponse: IPackageResponse; packagesList?: IPackagesListClass }
  ) {
    state.stoppingPolling = false;
    state.packageResponse = {
      ...state.packageResponse,
      ...packageResponse
    };

    state.digest = packageResponse.page.query.digest;
    state.queryBody = packageResponse.page.query.body;

    if (packagesList) {
      state.packagesList = packagesList;
    }

    const page = packageResponse && packageResponse.page;
    const query = packageResponse && packageResponse.page && packageResponse.page.query;

    const appliedFilters: StatPackageFilter | HotelFilter = query.lastFilter;

    if (!appliedFilters.price.max || !appliedFilters.price.min) {
      appliedFilters.price = query.digest.price;
    }

    if (!appliedFilters.hotels || appliedFilters.hotels.length === 0) {
      appliedFilters.hotels = query.digest.hotels;
    }

    if (!appliedFilters?.hotelStars?.min || !appliedFilters?.hotelStars?.max || appliedFilters?.hotelStars?.min <
            query.digest?.hotelStars?.min || appliedFilters?.hotelStars?.max > query.digest?.hotelStars?.max) {
      appliedFilters.hotelStars = query.digest.hotelStars;
    }

    if (!!query?.digest?.outFlightTime && !appliedFilters?.outFlightTime?.min ||
            !appliedFilters?.outFlightTime?.max ||
            appliedFilters?.outFlightTime?.min < query.digest?.outFlightTime?.min ||
            appliedFilters?.outFlightTime?.max >
            query.digest?.outFlightTime?.max) {
      appliedFilters.outFlightTime = query.digest.outFlightTime;
    }

    if (query.digest?.inFlightTime && !appliedFilters?.inFlightTime?.min || !appliedFilters?.inFlightTime?.max ||
            appliedFilters?.inFlightTime?.min < query.digest?.inFlightTime?.min || appliedFilters?.inFlightTime?.max >
            query?.digest?.inFlightTime?.max) {
      appliedFilters.inFlightTime = query.digest.inFlightTime;
    }

    if (packageResponse.page.query.searchStatus ===
            SearchStatusTypes.DEALS_POST_PROCESSED) {
      state.filter = new ProductFilter({
        lastFilter: appliedFilters,
        ...appliedFilters,
        ...state.savedTabsFilter ? state.savedTabsFilter : {},
        totalPages: page.totalPages
      });

      state.initialFilter = new ProductFilter({

        lastFilter: appliedFilters,
        ...query.digest,
        totalPages: page.totalPages
      });
      state.filter.page = page.number;
    }

    state.isLoadingPending = false;
  },
  [GET_GROUPED_RESULTS_BY_HOTEL.FAILED](state, error) {
    state.isLoadingPending = false;
    state.stoppingPolling = true;
    throw new Error(
      `getting group result process is error: ${
        error.data ? error.data.message : error
      }`
    );
  },
  [GET_AGENCY_PRICES.INITIAL](state) {
    state.isPricesPending = true;
  },
  [GET_AGENCY_PRICES.SUCCEEDED](state) {
    state.isPricesPending = false;
  },
  [GET_AGENCY_PRICES.FAILED](state, error) {
    state.isPricesPending = false;
    console.warn(error);
  },
  [GET_PRODUCT_DETAILS.INITIAL](state) {
    state.details = [];
    state.isDetailsPending = true;
  },
  [GET_PRODUCT_DETAILS.FAILED](state, error) {
    throw new Error(`getting group product process is error: ${error}`);
  },
  [GET_PRODUCT_DETAILS.SUCCEEDED](state, detail) {
    state.details.push(detail);
  },
  [SET_PRODUCTS_DETAILS.SUCCEEDED](
    state,
    {
      packageRes,
      productId,
      details
    }: {
            packageRes: IPackageClass
            productId: string
            details: IPackageProductDetailsClass
        }
  ) {
    const targetPackage = state.packagesList.getPackage(packageRes.hotel.content.id);
    if (targetPackage) {
      targetPackage.products.getProduct(productId).details = details;
      targetPackage.setDetailsPending(false);
    }
    packageRes.products.getProduct(productId).details = details;
    packageRes.setDetailsPending(false);
  },
  [SET_PRODUCTS_DETAILS.FAILED](state, {
    error,
    packageRes
  }: { error: Error, packageRes: IPackageClass }) {
    const targetPackage = state.packagesList.getPackage(packageRes.hotel.content.id);
    if (targetPackage) {
      targetPackage.setDetailsPending(false);
      targetPackage.isOpening = false;
    }
    packageRes.setDetailsPending(false);
    packageRes.isOpening = false;
    state.isDetailsError = true;
    console.error(`set product details process is error: ${error}`);
  },
  [CREATE_PACKAGE_CLASS.INITIAL](state) {
    state.isInitPackagePending = true;
  },
  [CREATE_PACKAGE_CLASS.SUCCEEDED](state, packageClass: IPackageClass) {
    state.currentPackage = packageClass;
    state.isInitPackagePending = false;
  },
  [CREATE_PACKAGE_CLASS.FAILED](state, error) {
    state.isInitPackagePending = false;
    throw new Error(`creating package class process is error: ${error}`);
  },
  [GET_PRODUCT_DETAILS_PRICES.INITIAL](state) {
    state.isPricesPending = true;
  },
  [GET_PRODUCT_DETAILS_PRICES.FAILED](state, error) {
    state.isPricesPending = false;
    throw new Error(`getting product details prices process is error: ${error}`);
  },
  [APPLY_FILTER.SUCCEEDED](state) {
    state.isFilterChanged = true;
  },
  [SET_FILTER](state, filter) {
    // state.filter = filter;
    state.savedTabsFilter = filter;
  },
  [APPLY_FILTER.FAILED](state, error) {
    throw new Error(`applying filter is error: ${error}`);
  },
  [RESET_FILTER](state) {
    if (!!state.filter && !!state.initialFilter) { // Check for filters exist
      // const filterContent: IProductFilter = state.initialFilter.content
      state.filter = null;
    }
    state.savedTabsFilter = null;
  },
  [THIS_FILTER_UNUSED](state) {
    state.isFilterChanged = false;
  },
  [GET_PRODUCT_DETAILS_PRICES.SUCCEEDED](
    state,
    { paxPrices }: { paxPrices: IPaxComplectPrice[] }
  ) {
    state.isPricesPending = false;
    if (!paxPrices || !paxPrices.length) {
      return;
    }

    const productId = paxPrices[0].productId;

    state.currentPackage.products.getProduct(
      productId
    ).paxComplectsPrices = paxPrices;
  },
  [SET_DETAILS_ERROR](state, isDetailsError) {
    state.isDetailsError = isDetailsError;
  },
  [SET_FACILITIES_WITHOUT_ICONS.INITIAL]() {
    //
  },
  [SET_FACILITIES_WITHOUT_ICONS.SUCCEEDED]() {
    //
  },
  [SET_FACILITIES_WITHOUT_ICONS.FAILED](state, error) {
    console.error(`set facilities without icons is error: ${error}`);
  },

  [GET_PACKAGE_RESULT_FOR_DIGEST.INITIAL](state) {
    state.digestForHotels = [];
  },
  [GET_PACKAGE_RESULT_FOR_DIGEST.SUCCEEDED](state, { digestForHotels }) {
    state.digestForHotels = digestForHotels;
  },
  [GET_PACKAGE_RESULT_FOR_DIGEST.FAILED](state, error) {
    console.error("digest error", error);
  }
};
export const packageResultStore: Module<IPackageResultState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};
