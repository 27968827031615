import ProductFilter from "modules/product-result/data/product-filter/product-filter.class";

import { IAdditional, IMinMax } from "modules/product-result/data/product-result.types";
import {
  HotelFilter,
  HotelSearchDigestPricesResource,
  StatPackageFilter,
  SupplierPriceResource
} from "tl-api-doc/typescript-generator";

interface IPartOfList {
  code: {
    code: string
  };
  price: number;
}

interface IProductFilter {
  lastFilter?: StatPackageFilter | HotelFilter;
  boarding: string[];
  dealFeatures: string[];
  sort: { [key: string]: "ASC" | "DESC" }[];
  price: IMinMax<number>;
  hotels: IPartOfList[];
  hotelStars: IMinMax<number>;
  providers: IPartOfList[];
  inFlightTime: IMinMax<string>;
  outFlightTime: IMinMax<string>;
  connectionTime: IMinMax<string>;
  hotelFacilities: IAdditional[];

  hotelFacilityIds: string[];
  totalPages: number;
  pricesTotal: HotelSearchDigestPricesResource;
}

interface IConvertToPackageResult {
  sort?: any[];
  boarding: string[];
  dealFeatures: string[];
  price: IMinMax<number>;
  hotelNames: string[];
  hotelStars: IMinMax<number>;
  providers: string[];
  inFlightTime: IMinMax<string>;
  outFlightTime: IMinMax<string>;
  connectionTime: IMinMax<string>;
  outDeparture: IMinMax<string>;
  inDeparture: IMinMax<string>;
  outArrival: IMinMax<string>;
  inArrival: IMinMax<string>;
  hotelFacilityIds: string[];
  page: number;
}

type IConvertToPackageResultForHotels =
  Pick<IConvertToPackageResult, "page" | "hotelStars" | "hotelFacilityIds" | "price" | "providers" | "boarding" | "hotelNames" | "sort">
  & { refundable: boolean; areaIds: string[] };

enum HotelSorting {
  "priceASC" = "priceASC",
  "priceDESC" = "priceDESC",
  "starASC" = "starASC",
  "starDESC" = "starDESC",
  "centerDistance" = "centerDistance",
}

interface IProductFilterClass {
  readonly content: IProductFilter;
  sorting: HotelSorting;
  areas: SupplierPriceResource[];
  boarding: string[];
  dealFeatures: string[];
  dealFeaturesCurrent: string[];
  price: number[];
  hotels: IPartOfList[];
  hotelsStars: number[];
  providers: IPartOfList[];
  inFlightTime: number[];
  outFlightTime: number[];
  connectionTime: number[];
  outDeparture: number[];
  inDeparture: number[];
  outArrival: number[];
  inArrival: number[];
  facilities: string[];
  hotelFacilities: IAdditional[];
  page: number;
  totalPages: number;
  refundable: boolean;

  // temp
  areasNames: string[];
  hotelsNames: string[];
  providersNames: string[];

  isEmptyConditionValue(conditionValue: number[]): boolean;

  convertMinutesToHHmm(minutes: number): string;

  convertMinutesToHM(minutes: number): string;

  convertMinutesToDuration(minutes: number): string;

  convertFilterToPackageResult(): IConvertToPackageResult;

  convertFilterToPackageResultForHotels(): IConvertToPackageResultForHotels;

  convertFilterDurationConditionValue(value: number[]): IMinMax<string>;

  convertFilterTimeConditionValue(value: number[]): IMinMax<string>;
}

export {
  // enums
  HotelSorting,
  // classes
  ProductFilter,
  // intefaces
  IConvertToPackageResult,
  IConvertToPackageResultForHotels,
  IPartOfList,
  IProductFilter,
  IProductFilterClass
};
