import {
  IPackage,
  IPackageClass,
  IPackageHotelClass,
  IPackageProduct,
  IPackageProductPrice,
  IPackageProductsListClass,
  PackageHotel,
  PackageProductsList
} from "modules/product-result/data/product-result.types";

export default class Package implements IPackageClass {
  // export default class Package implements IPackageClass {
  private _hotel: IPackageHotelClass;
  // private _productsPrices: IPackageProductPrice[]
  // private _productsIds: Array<IPackageProduct['productId']> = []
  private _products: IPackageProductsListClass;
  private _isOpening = false;
  private _isDetailsPending: boolean;
  private _isShowDetails: boolean;

  constructor(private _package: IPackage) {
    this._hotel = new PackageHotel(_package.hotel);
    // const t = JSON.parse(JSON.stringify(_package.products))
    this._products = new PackageProductsList(_package.products);
    this._isDetailsPending = false;
    this._isShowDetails = false;

    // this._package.products.map(product => this._productsIds.push(product.productId))
  }

  get isShowDetails(): boolean {
    return this._isShowDetails;
  }

  set isShowDetails(value: boolean) {
    this._isShowDetails = value;
  }

  get content(): IPackageClass["content"] {
    return this._package;
  }

  get hotel(): IPackageHotelClass {
    return this._hotel;
  }

  get products(): IPackageProductsListClass {
    return this._products;
  }

  get isOpening(): boolean {
    return this._isOpening;
  }

  set isOpening(value: boolean) {
    this._isOpening = value;
  }

  get isDetailsPending(): boolean {
    return this._isDetailsPending;
  }

  get isDetailsError(): boolean {
    return this._products.content.every(product => product.details && !!product.details.status && product.details.status === "ERROR");
  }

  setDetailsPending(status: boolean): void {
    this._isDetailsPending = status;
  }

  convertToStorage(): any {
    return {
      content: this.content,
      details: this.products.content.map(pr => [
        pr.content.productId,
        pr.details.content
      ]),
      prices: this.products.pricesList,
      products: this.products.content
    };
  }
}
