import { ISearchQueryBody } from "./types/fly-search.types";

export default ({ http }: any) => ({
  beginSearch: (
    aia: boolean,
    query: ISearchQueryBody,
    websiteKey: string
  ) => {
    if (websiteKey) {
      return http.post("/customer/search/flight/requests", {
        ...query,
        websiteKey,
        websiteType: aia ? "AGENCY_TERMINAL" : "AGENCY_SHOWCASE"
      });
    } else {
      return http.post("/customer/search/flight/requests", { ...query });
    }
  }
});
