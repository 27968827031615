import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";

import moment from "moment";

import Locale from "./i18n.locale.class";
import { IFlyLocaleState } from "./types/i18n.types";
import { instances } from "../../common/http/createHttp";
import { RootState } from "applications/desktop/store";

// TODO: realize helper-module for action-mutation
const CHANGE_LOCALE = "CHANGE_LOCALE";

const namespaced = true;

const state: IFlyLocaleState = {
  localeClass: new Locale("en"),
  locales: {
    en: "en-US",
    he: "iw-IL",
    ru: "ru-RU"
  }
};

const getters: GetterTree<IFlyLocaleState, RootState> = {
  currentFormattedLocale: (state: IFlyLocaleState) =>
    state.localeClass.formattedLocale,
  currentLocale: (state: IFlyLocaleState) => state.localeClass.locale,
  localeClass: (state: IFlyLocaleState) => state.localeClass,
  localeList: (state: IFlyLocaleState) => state.locales,
  usedLocale: (state: IFlyLocaleState) => state.localeClass.usedLocale
};

const actions: ActionTree<IFlyLocaleState,
    RootState> = {
      async setDefaultLocale({ dispatch, getters }) {
        getters.localeClass.changeLocale(getters.usedLocale);
        await dispatch("changeLocale", getters.usedLocale);
      },
      async changeLocale({ commit, getters, dispatch }: ActionContext<IFlyLocaleState, RootState>, locale: string) {
        if (getters.currentLocale !== locale) {
          getters.localeClass.changeLocale(locale);
          commit(CHANGE_LOCALE, locale);
          getters.localeClass.applyLocale(locale);
          window.localStorage.setItem("app_locale", locale);
        }
        const dir: string = locale === "he" ? "rtl" : "ltr";
        document.body.setAttribute("dir", dir);

        await dispatch("applyAxiosHeaderLocale");
      },
      async applyAxiosHeaderLocale({ state }: ActionContext<IFlyLocaleState, RootState>) {
        const locale: string = state.localeClass.locale;
        Object.values(instances).forEach(instance => {
          instance.defaults.headers.common["Accept-Language"] = locale;
        });
      }
    };

const mutations: MutationTree<IFlyLocaleState> = {
  [CHANGE_LOCALE](state, locale: string) {
    state.localeClass.locale = locale;
    moment.locale(locale);
  }
};

export const i18nStore: Module<IFlyLocaleState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
};
