import { AxiosPromise } from "axios";
import {
  CcPaymentEvaluationResource,
  DocumentStatus,
  ExchangeRateResource,
  PaymentTransactionResource,
  PaymentTransactionStatus,
  ReservationRequirementsResource,
  RootVoucherResource
} from "tl-api-doc/typescript-generator";
import { ApiBaseResource } from "../../../common/types/types";
import { CompanyType } from "tl-api-doc/typescript-generator/assembly";

const url = window.location.origin;

export const PAYMENT_OPTION_URLS: { [key: string]: string } = {
  cancelUrl: `${url}/#/cancel-payment`,
  errorUrl: `${url}/#/error-payment`,
  goodUrl: `${url}/#/success-payment`
};

export type PayRequest = (
  ccOwnerType: "CUSTOMER" | "AGENT",
  cancelUrl: string,
  errorUrl: string,
  goodUrl: string,
  numberOfPayments: number,
  voucherId: string,
  orderId: string
) => AxiosPromise<{ paymentUrl: string }>;

export function getPaymentCallbackUrls(orderId: string, uuid: string) {
  // use PAYMENT_OPTION_URLS
  return {
    cancelUrl: PAYMENT_OPTION_URLS.cancelUrl + `/${orderId}`, // &uuid=${uuid}`,
    errorUrl: PAYMENT_OPTION_URLS.errorUrl + `/${orderId}`, // &uuid=${uuid}`,
    goodUrl: PAYMENT_OPTION_URLS.goodUrl + `/${orderId}` // &uuid=${uuid}`
  };
}

export interface IItemPaymentMeans {
  "itemId": string // "9a130941-f4b5-3494-a550-7bd8b3824de1",
  "ticketingAllowance"?: "DISABLED",
  "e2eBookingWillBeCalled": boolean,
}

export interface IPaymentMeans {
  "orderId": string // "f1584b99-5a47-3098-aad7-5bb8d29e9734",
  "items": IItemPaymentMeans[],
  "e2eBookingWillBeCalled": true,
  "paymentMeans": ["ACCOUNT_BALANCE", "CREDIT_CARD"]
}

export interface IPaymentState {
  voucher: RootVoucherResource,
  exchangeRateResource: ExchangeRateResource[],

  orderId: string,
  isPaymentProcessing: boolean,
  paymentError: any,

  paymentUrl: string,

  // paymentCount count from selector
  // paymentCountOptions from BE
  paymentCount: 1 | 2 | 3 | 4,
  paymentCountOptions: number[],

  isPaymentUrlLoading: boolean,
  isPaymentUrlError: boolean,
  paymentUrlError: any,
  payWindowInterval: number,

  paymentMeans: IPaymentMeans
  isPaymentMeansPending: boolean
  paymentMeansError: any

  openPaymentUrlError: any,
  isStartOpenPaymentUrl: boolean,
  isSuccessOpenPaymentUrl: boolean,

  isBadOpenPaymentUrlModal: boolean,

  paymentStatus: PaymentTransactionStatus,
  rawPaymentStatus: PaymentTransactionResource,
  isPaymentStatusLoading: boolean,
  isPaymentStatusError: boolean,
  paymentStatusError: any,

  isPaymentStatusPolling: boolean,

  retryCount: number
  paymentEvaluation: CcPaymentEvaluationResource
  paymentEvaluationByAmount: CcPaymentEvaluationResource
  paymentEvaluationPending: boolean
  paymentEvaluationError: any

  reservationRequirements: ReservationRequirementsResource
  reservationRequirementsByAmount: ReservationRequirementsResource
  reservationRequirementsPending: boolean
  reservationRequirementsError: any
}

export interface IGetPaymentUrlPayload {
  orderId: string;
  uuid: string;
  ccOwnerType: "CUSTOMER" | "AGENT";
  voucherId: string,
  amount?: number,
  issueTickets?: boolean;
}

export type GetPaymentUrlReturn = Promise<string>
export type GetPaymentUrlHandler = (payload: IGetPaymentUrlPayload) => GetPaymentUrlReturn

export interface IGoToPaymentPayload {
  uuid: string,
  orderId: string,
  ccOwnerType: "CUSTOMER" | "AGENT",
  voucherId: string,
  amount?: number,
  issueTickets?: boolean;
}

export type GoToPaymentHandler = (payload: IGoToPaymentPayload) => Promise<void>

export interface IOpenPaymentUrlPayload {
  paymentUrl: string;
}

export interface IGetPaymentStatusPayload {
  orderId: string;
}

export type GetPaymentStatusHandler = Promise<PaymentTransactionResource>

export interface IPollPaymentStatus {
  orderId: string;
}

export type PollPaymentStatusHandler = (payload: IPollPaymentStatus) => Promise<PaymentTransactionResource>

export interface IGetPaymentEvaluationPayload {
  uuid: string,
  orderId: string,
  numberOfPayments: number,
  ccOwnerType: "CUSTOMER" | "AGENT",
  voucherId: string,
  amount?: number,
}

export type GetPaymentEvaluationHandler = (payload: IGetPaymentEvaluationPayload) => Promise<CcPaymentEvaluationResource>

// getReservationRequirements

export interface IGetReservationRequirementsPayload {
  uuid: string,
  orderId: string,
  numberOfPayments: number,
  ccOwnerType: "CUSTOMER" | "AGENT",
  voucherId?: string,
  amount?: number,
}

export type GetReservationRequirementsHandler = (payload: IGetReservationRequirementsPayload) => Promise<ReservationRequirementsResource>

export interface IStartPaymentPayload {
  payType: "deposit" | "card",
  cardType: "CUSTOMER" | "AGENT",
  amount: number,
  count: number;
  issueTickets?: boolean;
}

export interface IChangeVoucherStatusPayload {
  companyType: CompanyType;
  orderId: string;
  orderItemId: string;
  statusChangeData: {
    newStatus: DocumentStatus,
    remark: {
      body: string,
      subject: string
    }
  };
  uuid: string;
  voucherId: string;
}

export type ApiCurrencyRates = ApiBaseResource<ExchangeRateResource>
