const hosts: string[] = [
  "https://beeasy.pro/",
  "https://dev1.tripsoft.pro/control/",
  "https://dev1.tripsoft.pro/",
  "https://travelboost.co.il/",
  "http://localhost:8080/"
];
const host: string = window.location.origin + window.location.pathname;

enum ConfigProductTypes {
    "PAYMENTS" = "PAYMENTS",
    "REGULAR_FLIGHT" = "REGULAR_FLIGHT",
    "STATIC_PACKAGE" = "STATIC_PACKAGE",
    "GDS" = "GDS"
}

enum ConfigSupplierTypes {
    "CREDIT_GUARD" = "CREDIT_GUARD",
    "PELECARD" = "PELECARD",
    "AMADEUS" = "AMADEUS",
    "KIWI" = "KIWI",
    "ALP_PACKAGE" = "ALP_PACKAGE",
    "COMMON" = "COMMON"
}
export {
  ConfigProductTypes,
  ConfigSupplierTypes,
  hosts,
  host
};
