import Query from "modules/product-search/data/product-search.query/product-search.query.class";
import { IPackageQueryClass, IQuery, ISearchSegment } from "modules/product-search/data/product-search.types";
import { IConvertedHotelQuery } from "../product-search.query/product-search.query.types";

export default class PackageQuery extends Query implements IPackageQueryClass {
  constructor(private _packageQuery: IQuery) {
    super(_packageQuery);
  }

  getFirstSegment(): ISearchSegment {
    const segment: ISearchSegment = {
      ...super.getFirstSegment(),
      departureDate: this.segments?.[0]?.departureDate,
      fromAirport: {
        city: {
          code: "TLV",
          country: {
            code: "IL",
            locale: "EN",
            name: "Israel"
          },
          hasMultiairport: true,
          locale: "EN",
          name: "Tel Aviv"
        },
        code: "TLV",
        locale: "EN",
        name: "Ben Gurion Airport"
      }
    };

    return super.getFirstSegment(segment);
  }

  convertToRequest(): IConvertedHotelQuery {
    return {
      rooms: this.rooms,
      residency: this.residency?.code,
      paxes: this.paxes,
      rawSegments: this.segments,
      segments: [
        this.convertSegment(this.getFirstSegment()),
        this.convertSegment(this.getSecondSegment())
      ]
    };
  }
}
