import { ActionContext, Module } from "vuex";

import { poll } from "common/polling";

import paymentApi from "./payment.api";
import actionHelper from "utils/actionHelper";
import {
  GetPaymentEvaluationHandler,
  GetPaymentStatusHandler,
  GetPaymentUrlReturn,
  GetReservationRequirementsHandler,
  IChangeVoucherStatusPayload,
  IGetPaymentEvaluationPayload,
  IGetPaymentStatusPayload,
  IGetPaymentUrlPayload,
  IGetReservationRequirementsPayload,
  IGoToPaymentPayload,
  IOpenPaymentUrlPayload,
  IPaymentMeans,
  IPaymentState,
  IPollPaymentStatus
} from "./types/payment.types";
import {
  AgentVoucherPaymentCreate,
  CcPaymentEvaluationResource,
  ExchangeRateResource,
  PaymentTransactionResource,
  ReservationRequirementsResource,
  RootVoucherResource,
  VoucherStatusChangeResource
} from "tl-api-doc/typescript-generator";
import RootName from "../root/root.name";
import { AxiosResponse } from "axios";
import { RootState } from "applications/desktop/store";
import { ApiBaseResource } from "../../common/types/types";

const SET_PAYMENT_URL = actionHelper("SET_PAYMENT_URL");
const SET_PAYMENT_STATUS = actionHelper("SET_PAYMENT_STATUS");

const SET_PAYMENT_STATUS_POLLING = actionHelper("SET_PAYMENT_STATUS_POLLING");

const OPEN_PAYMENT_URL = actionHelper("OPEN_PAYMENT_URL");
const GO_TO_PAYMENT = actionHelper("GO_TO_PAYMENT");
const GO_RETRY_PAYMENT = actionHelper("GO_RETRY_PAYMENT");

const OPEN_BAD_PAYMENT_URL_MODAL = actionHelper("OPEN_BAD_PAYMENT_URL_MODAL");
const SET_PAYMENT_COUNT = actionHelper("SET_PAYMENT_COUNT");

const SET_PAYMENT_COUNT_OPTIONS = actionHelper("SET_PAYMENT_COUNT_OPTIONS");

const SET_PAYMENT_MEANS = actionHelper("SET_PAYMENT_MEANS");
const SET_PAYMENT_EVALUATION = actionHelper("SET_PAYMENT_EVALUATION");
const SET_PAYMENT_EVALUATION_BY_AMOUNT = actionHelper("SET_PAYMENT_EVALUATION_BY_AMOUNT");

// Requirements
const SET_PAYMENT_REQUIREMENTS = actionHelper("SET_PAYMENT_REQUIREMENTS");
const SET_PAYMENT_REQUIREMENTS_BY_AMOUNT = actionHelper("SET_PAYMENT_REQUIREMENTS_BY_AMOUNT");

const SET_CURRENCY_RATES = actionHelper("SET_CURRENCY_RATES");

// createVoucher

const SET_VOUCHER = actionHelper("SET_VOUCHER");
const SET_VOUCHER_PAYMENT = actionHelper("SET_VOUCHER_PAYMENT");

const CHANGE_VOUCHER_STATUS = actionHelper("CHANGE_VOUCHER_STATUS");
const validation = (paymentStatus: PaymentTransactionResource) => {
  switch (paymentStatus.status) {
  case "NEW":
    return false;
  case "INITIALISED":
    return false;
  case "INITIALISATION_FAILED":
    return true;
  case "PAID":
    return true;
  case "PAYMENT_FAILED":
    return true;
  case "CANCELLED":
    return true;
  default:
    return false;
  }
};
export default ({
  api
}: {
  api: ReturnType<typeof paymentApi>;
}): Module<IPaymentState, RootState> => ({
  namespaced: true,

  state: {
    voucher: null,
    exchangeRateResource: [],

    orderId: null,
    isPaymentProcessing: false,
    paymentError: null,

    paymentUrl: null,

    paymentCount: 1,
    paymentCountOptions: [],

    isPaymentUrlLoading: false,
    isPaymentUrlError: false,
    paymentUrlError: null,

    payWindowInterval: null,

    paymentMeans: null,
    isPaymentMeansPending: false,
    paymentMeansError: null,

    openPaymentUrlError: null,
    isSuccessOpenPaymentUrl: false,
    isStartOpenPaymentUrl: false,

    isBadOpenPaymentUrlModal: false,

    rawPaymentStatus: null,
    paymentStatus: "NEW",
    isPaymentStatusLoading: false,
    isPaymentStatusError: false,
    paymentStatusError: null,

    isPaymentStatusPolling: false,

    retryCount: 0,
    paymentEvaluation: null,
    paymentEvaluationByAmount: null,
    paymentEvaluationPending: false,
    paymentEvaluationError: null,

    reservationRequirements: null,
    reservationRequirementsByAmount: null,
    reservationRequirementsPending: false,
    reservationRequirementsError: null
  },

  getters: {
    voucher: (state) => state.voucher,
    paymentEvaluationPending: (state) => state.paymentEvaluationPending,
    paymentEvaluation: (state) => state.paymentEvaluation,
    paymentEvaluationByAmount: (state) => state.paymentEvaluationByAmount,
    reservationRequirements: (state) => state.reservationRequirements,
    reservationRequirementsPending: (state) => state.reservationRequirementsPending,
    exchangeRateResource: (state) => state.exchangeRateResource,
    paymentUrl: (state) => state.paymentUrl,

    paymentCountOptions: (state) => state.paymentCountOptions,
    paymentCount: (state) => state.paymentCount,

    isPaymentUrlLoading: (state) => state.isPaymentUrlLoading,
    isPaymentSuccess: (state) =>
      state.rawPaymentStatus ? validation(state.rawPaymentStatus) : false,
    isPaymentStatusLoading: (state) => state.isPaymentStatusLoading,

    paymentMeans: (state) => state.paymentMeans,
    isPaymentMeansPending: (state) => state.isPaymentMeansPending,
    paymentMeansError: (state) => state.paymentMeansError,

    openPaymentUrlError: (state) => state.openPaymentUrlError,
    isSuccessOpenPaymentUrl: (state) => state.isSuccessOpenPaymentUrl,
    isBadOpenPaymentUrlModal: (state) => state.isBadOpenPaymentUrlModal,
    isPaymentStatusError: (state) => state.isPaymentStatusError,
    paymentStatusError: (state) => state.paymentStatusError,
    paymentStatus: (state) => state.paymentStatus,
    isPaymentStatusPolling: (state) => state.isPaymentStatusPolling,
    isPaymentProcessing: (state) => state.isPaymentProcessing,
    paymentError: (state) => state.paymentError
  },

  actions: {
    // payForVoucherFromDeposit
    async payForVoucherFromDeposit({ commit }, {
      uuid,
      voucherId,
      amountFromDeposit,
      issueTickets
    }: {
      uuid: string;
      voucherId: string;
      amountFromDeposit: number;
      issueTickets?: boolean;
    }) {
      commit(SET_VOUCHER_PAYMENT.INITIAL);
      try {
        const paymentParams: AgentVoucherPaymentCreate = {
          amountFromObligo: 0,
          paymentReference: "",
          paymentType: "",
          remark: "",
          voucherIds: [voucherId],
          amountFromDeposit,
          issueTickets
        };
        const response: VoucherStatusChangeResource = (await api.payForVoucherFromDeposit(
          uuid,
          paymentParams
        )).data;
        commit(SET_VOUCHER_PAYMENT.SUCCEEDED, response);
        return Promise.resolve(response);
      } catch (e) {
        commit(SET_VOUCHER_PAYMENT.FAILED, e);
        return Promise.reject(e);
      }
    },

    // createVoucher
    async createVoucher({ commit }, {
      orderId,
      uuid,
      orderItemIds
    }: {
      orderId: string,
      uuid: string,
      orderItemIds: string[]
    }): Promise<RootVoucherResource> {
      commit(SET_VOUCHER.INITIAL);
      try {
        const response: RootVoucherResource = (await api.createVoucher(orderId, uuid, orderItemIds)).data;
        commit(SET_VOUCHER.SUCCEEDED, response);
        return Promise.resolve(response);
      } catch (e) {
        commit(SET_VOUCHER.FAILED, e);
        return Promise.reject(e);
      }
    },
    // changeVoucherStatus
    async changeVoucherStatus(
      {
        commit,
        dispatch
      }: ActionContext<IPaymentState, RootState>,
      {
        companyType,
        orderId,
        orderItemId,
        statusChangeData,
        uuid,
        voucherId
      }: IChangeVoucherStatusPayload
    ) {
      try {
        commit(CHANGE_VOUCHER_STATUS.INITIAL);
        const voucherStatusChange = (await api.changeVoucherStatus({
          dataVoucher: {
            costCommission: 0,
            costCurrency: "USD",
            costMarkup: 0,
            newStatus: statusChangeData.newStatus,
            remarkBody: statusChangeData.remark.body,
            remarkSubject: statusChangeData.remark.subject,
            voucherId
          },
          companyType,
          orderId,
          orderItemId,
          uuid
        })).data;

        commit(CHANGE_VOUCHER_STATUS.SUCCEEDED, { voucherStatusChange });
        return voucherStatusChange;
      } catch (error) {
        commit(CHANGE_VOUCHER_STATUS.FAILED, error);
      }
    },

    async getCurrencyRates({ commit }, uuid: string): Promise<ExchangeRateResource[]> {
      commit(SET_CURRENCY_RATES.INITIAL);
      try {
        const response: ApiBaseResource<ExchangeRateResource> = (await api.getCurrencyRates(uuid)).data;
        commit(SET_CURRENCY_RATES.SUCCEEDED, response.content);
        return response.content;
      } catch (e) {
        commit(SET_CURRENCY_RATES.FAILED, e);
        return Promise.reject(e);
      }
    },

    async getPaymentMeans(
      { commit },
      {
        orderId,
        uuid
      }: {
        orderId: string;
        uuid: string;
      }
    ) {
      commit(SET_PAYMENT_MEANS.INITIAL);
      try {
        const paymentMeans: AxiosResponse<IPaymentMeans> =
          await api.getPaymentMeans({
            orderId,
            uuid
          });
        commit(SET_PAYMENT_MEANS.SUCCEEDED, paymentMeans.data);
        return Promise.resolve(paymentMeans.data);
      } catch (e) {
        commit(SET_PAYMENT_MEANS.FAILED, e);
        return Promise.reject();
      }
    },

    async getPaymentCountOptions({ commit }, { orderId }: { orderId: string }) {
      commit(SET_PAYMENT_COUNT_OPTIONS.INITIAL);
      try {
        const paymentCountOptions = await api.getPaymentCount({ orderId });
        commit(SET_PAYMENT_COUNT_OPTIONS.SUCCEEDED, paymentCountOptions.data);
        return Promise.resolve(paymentCountOptions.data);
      } catch (e) {
        commit(SET_PAYMENT_COUNT_OPTIONS.FAILED, e);
        return Promise.reject(e);
      }
    },

    async selectPaymentCount({ commit }, count: number) {
      commit(SET_PAYMENT_COUNT.SUCCEEDED, count);
    },
    async callStopPaymentStatusPolling({ commit }) {
      commit(SET_PAYMENT_STATUS_POLLING.FAILED, "stoped");
    },
    async getPaymentUrl(
      {
        commit,
        state
      },
      {
        orderId,
        uuid,
        ccOwnerType,
        voucherId,
        amount,
        issueTickets
      }: IGetPaymentUrlPayload
    ): GetPaymentUrlReturn {
      try {
        commit(SET_PAYMENT_URL.INITIAL);
        const numberOfPayments = state.paymentCount;
        const paymentUrl = await api.getPaymentUrl(
          orderId,
          uuid,
          numberOfPayments,
          ccOwnerType,
          amount,
          voucherId,
          issueTickets
        );
        commit(SET_PAYMENT_URL.SUCCEEDED, paymentUrl.data.paymentUrl);
        return Promise.resolve(paymentUrl.data.paymentUrl);
      } catch (error) {
        commit(SET_PAYMENT_URL.FAILED, error);
        return Promise.reject(error);
      }
    },

    async cancelPayment(
      _,
      {
        orderId,
        uuid
      }: { orderId: string; uuid: string }
    ) {
      return api.cancelPayment(orderId, uuid);
    },

    async getPaymentStatus(
      { commit },
      { orderId }: IGetPaymentStatusPayload
    ): GetPaymentStatusHandler {
      commit(SET_PAYMENT_STATUS.INITIAL);

      try {
        const paymentStatus: PaymentTransactionResource = (
          await api.getPaymentStatus(orderId)
        ).data;
        commit(SET_PAYMENT_STATUS.SUCCEEDED, paymentStatus);
        return Promise.resolve(paymentStatus);
      } catch (error) {
        commit(SET_PAYMENT_STATUS.FAILED, error);
        return Promise.reject(error);
      }
    },

    async pollPaymentStatus(
      {
        state,
        dispatch
      },
      { orderId }: IPollPaymentStatus
    ): Promise<PaymentTransactionResource> {
      // add timeout for polling payment status 15 minutes

      const timeout = 1000 * 60 * 11;
      setTimeout(() => {
        dispatch("callStopPaymentStatusPolling");
        clearInterval(state.payWindowInterval);
      }, timeout);

      async function getPaymentStatus(): GetPaymentStatusHandler {
        return dispatch("getPaymentStatus", { orderId });
      }

      function stoppingFn(): boolean {
        return orderId !== state.orderId || !state.isPaymentStatusPolling;
      }

      return poll<PaymentTransactionResource>(
        getPaymentStatus,
        validation,
        1000,
        1000 * 10,
        stoppingFn
      );
    },

    async openBadPaymentUrlModal({ commit }) {
      commit(OPEN_BAD_PAYMENT_URL_MODAL.INITIAL);
    },
    async closeBadPaymentUrlModal({ commit }) {
      commit(OPEN_BAD_PAYMENT_URL_MODAL.SUCCEEDED);
    },

    async openPayment(
      {
        commit,
        dispatch,
        state
      },
      { paymentUrl }: IOpenPaymentUrlPayload
    ) {
      commit(OPEN_PAYMENT_URL.INITIAL);

      if (!paymentUrl) {
        const error = Error("paymentUrl is empty");
        return Promise.reject(error);
      }
      const payWindow = window.open(paymentUrl, "_blank");

      const wait = (time: number) =>
        new Promise<void>(resolve => setTimeout(() => resolve(), time));

      const intervalTimer = setInterval(async function() {
        if (payWindow.closed) {
          clearInterval(intervalTimer);

          await wait(1000 * 5);

          const payStatus: PaymentTransactionResource = await dispatch(
            "getPaymentStatus",
            { orderId: state.orderId }
          );
          const isStatusInProgress = validation(payStatus);

          if (!isStatusInProgress) {
            dispatch("cancelPayment", { orderId: state.orderId });

            const payStatus: PaymentTransactionResource = await dispatch(
              "getPaymentStatus",
              { orderId: state.orderId }
            );

            dispatch("callStopPaymentStatusPolling");
          }
        }
      }, 1000);

      if (
        !payWindow ||
        payWindow.closed ||
        typeof payWindow.closed === "undefined"
      ) {
        // Всплывающие окна заблокированы или не удалось открыть окно по какой-то другой причине
        const error = Error("Не удалось открыть окно оплаты");
        commit(OPEN_PAYMENT_URL.FAILED, error);

        dispatch("openBadPaymentUrlModal");

        // return Promise.reject(error);
        // alert(
        //   "Мы не смогли открыть новое окно. Пожалуйста, разрешите всплывающие окна для этого сайта в настройках вашего браузера.");
      } else {
        commit(OPEN_PAYMENT_URL.SUCCEEDED, intervalTimer);
        return Promise.resolve();
      }
    },

    // getReservationRequirements

    async getReservationRequirements({ commit }, {
      orderId,
      uuid,
      numberOfPayments,
      ccOwnerType = "CUSTOMER",
      voucherId,
      amount
    }: IGetReservationRequirementsPayload): ReturnType<GetReservationRequirementsHandler> {
      commit(SET_PAYMENT_REQUIREMENTS.INITIAL);
      try {
        const response = await api.getReservationRequirements(
          orderId,
          uuid,
          numberOfPayments,
          ccOwnerType,
          voucherId,
          amount
        );
        commit(SET_PAYMENT_REQUIREMENTS.SUCCEEDED, response.data);
        return Promise.resolve(response.data);
      } catch (e) {
        commit(SET_PAYMENT_REQUIREMENTS.FAILED, e);
        return Promise.reject(e);
      }
    },
    // getReservationRequirements

    async getReservationRequirementsByAmount({ commit }, {
      orderId,
      uuid,
      numberOfPayments,
      ccOwnerType = "CUSTOMER",
      voucherId,
      amount
    }: IGetReservationRequirementsPayload): ReturnType<GetReservationRequirementsHandler> {
      commit(SET_PAYMENT_REQUIREMENTS_BY_AMOUNT.INITIAL);
      try {
        const response = await api.getReservationRequirements(
          orderId,
          uuid,
          numberOfPayments,
          ccOwnerType,
          voucherId,
          amount
        );
        commit(SET_PAYMENT_REQUIREMENTS_BY_AMOUNT.SUCCEEDED, response.data);
        return Promise.resolve(response.data);
      } catch (e) {
        commit(SET_PAYMENT_REQUIREMENTS_BY_AMOUNT.FAILED, e);
        return Promise.reject(e);
      }
    },

    async getPaymentEvaluation({ commit }, {
      orderId,
      uuid,
      numberOfPayments,
      ccOwnerType = "CUSTOMER",
      voucherId,
      amount
    }: IGetPaymentEvaluationPayload): ReturnType<GetPaymentEvaluationHandler> {
      commit(SET_PAYMENT_EVALUATION.INITIAL);
      try {
        const response = await api.getPaymentEvaluation(
          orderId,
          uuid,
          numberOfPayments,
          ccOwnerType,
          voucherId,
          amount
        );
        commit(SET_PAYMENT_EVALUATION.SUCCEEDED, response.data);
        return Promise.resolve(response.data);
      } catch (e) {
        commit(SET_PAYMENT_EVALUATION.FAILED, e);
        return Promise.reject(e);
      }
    },
    async getPaymentEvaluationByAmount({ commit }, {
      orderId,
      uuid,
      numberOfPayments,
      ccOwnerType = "CUSTOMER",
      voucherId,
      amount
    }: IGetPaymentEvaluationPayload): ReturnType<GetPaymentEvaluationHandler> {
      commit(SET_PAYMENT_EVALUATION_BY_AMOUNT.INITIAL);
      try {
        const response = await api.getPaymentEvaluation(
          orderId,
          uuid,
          numberOfPayments,
          ccOwnerType,
          voucherId,
          amount
        );
        commit(SET_PAYMENT_EVALUATION_BY_AMOUNT.SUCCEEDED, response.data);
        return Promise.resolve(response.data);
      } catch (e) {
        commit(SET_PAYMENT_EVALUATION_BY_AMOUNT.FAILED, e);
        return Promise.reject(e);
      }
    },

    async goToPayment(
      {
        commit,
        state,
        dispatch
      },
      {
        orderId,
        uuid,
        ccOwnerType,
        amount,
        voucherId,
        issueTickets
      }: IGoToPaymentPayload
    ) {
      commit(GO_TO_PAYMENT.INITIAL, orderId);

      try {
        await dispatch("getPaymentUrl", {
          orderId,
          uuid,
          ccOwnerType,
          amount,
          voucherId,
          issueTickets
        });

        if (state.paymentUrl) {
          dispatch("openPayment", { paymentUrl: state.paymentUrl });
        }

        commit(SET_PAYMENT_STATUS_POLLING.INITIAL);

        try {
          await dispatch("pollPaymentStatus", { orderId });
        } catch (e) {
          if (e === "stoped") {
            commit(GO_TO_PAYMENT.FAILED, e);
            return Promise.resolve();
          }
          throw e;
        }

        commit(SET_PAYMENT_STATUS_POLLING.SUCCEEDED);

        commit(GO_TO_PAYMENT.SUCCEEDED);
        return Promise.resolve();
      } catch (e) {
        commit(GO_TO_PAYMENT.FAILED, e);
        return Promise.reject(e);
      }
    },
    async retryPayment({
      commit,
      state,
      dispatch,
      rootGetters
    }) {
      try {
        if (state.isPaymentProcessing) {
          return;
        }
        if (state.retryCount > 3) {
          console.log("retry count exceeded");
          throw new Error("retry count exceeded");
        }
        if (!state.orderId) {
          console.log("no order id");
        }
        commit(GO_RETRY_PAYMENT.INITIAL);
        await dispatch("getPaymentUrl", {
          orderId: state.orderId,
          uuid: rootGetters[`${RootName}/uuid`]
        });

        if (state.paymentUrl) {
          dispatch("openPayment", { paymentUrl: state.paymentUrl });
        }

        commit(SET_PAYMENT_STATUS_POLLING.INITIAL);

        try {
          await dispatch("pollPaymentStatus", { orderId: state.orderId });
        } catch (e) {
          if (e === "stoped") {
            commit(GO_RETRY_PAYMENT.FAILED, e);
            return Promise.resolve();
          }
          throw e;
        }

        commit(SET_PAYMENT_STATUS_POLLING.SUCCEEDED);

        commit(GO_RETRY_PAYMENT.SUCCEEDED);
        return Promise.resolve();
      } catch (e) {
        commit(GO_RETRY_PAYMENT.FAILED, e);
        return Promise.reject(e);
      }
    }
  },

  mutations: {
    [SET_PAYMENT_MEANS.INITIAL](state) {
      state.isPaymentMeansPending = true;
      state.paymentMeans = null;
      state.paymentMeansError = null;
    },
    [SET_PAYMENT_MEANS.SUCCEEDED](state, paymentMeans) {
      state.isPaymentMeansPending = false;
      state.paymentMeans = paymentMeans;
    },
    [SET_PAYMENT_MEANS.FAILED](state, error) {
      state.isPaymentMeansPending = false;
      state.paymentMeansError = error;
    },

    [SET_PAYMENT_COUNT_OPTIONS.INITIAL](state) {
      state.paymentCountOptions = [];
    },
    [SET_PAYMENT_COUNT_OPTIONS.SUCCEEDED](state, paymentCountOptions) {
      state.paymentCountOptions = paymentCountOptions;
    },
    [SET_PAYMENT_COUNT_OPTIONS.FAILED](state, error) {
      console.error(error);
      state.paymentCountOptions = [];
    },

    [SET_PAYMENT_COUNT.SUCCEEDED](state: IPaymentState, count: 1 | 2 | 3 | 4) {
      state.paymentCount = count;
    },
    [GO_TO_PAYMENT.INITIAL](state: IPaymentState, orderId: string) {
      state.orderId = orderId;
      state.isPaymentProcessing = true;
      state.retryCount = 0;
    },
    [GO_TO_PAYMENT.SUCCEEDED](state: IPaymentState) {
      state.isPaymentProcessing = false;
      state.retryCount = 0;
      state.paymentError = null;
      state.paymentUrl = null;
    },
    [GO_TO_PAYMENT.FAILED](state: IPaymentState, error: any) {
      state.isPaymentProcessing = false;
      state.paymentError = error;
      state.paymentUrl = null;
      console.error(error);
    },
    [OPEN_PAYMENT_URL.INITIAL](state: IPaymentState) {
      state.isStartOpenPaymentUrl = true;
      state.isSuccessOpenPaymentUrl = false;
      state.openPaymentUrlError = null;
    },
    [OPEN_PAYMENT_URL.SUCCEEDED](state: IPaymentState, payWindowInterval: number) {
      state.payWindowInterval = payWindowInterval;

      state.isStartOpenPaymentUrl = false;
      state.isSuccessOpenPaymentUrl = true;
      state.openPaymentUrlError = null;
    },
    [OPEN_PAYMENT_URL.FAILED](state: IPaymentState, error: any) {
      state.isStartOpenPaymentUrl = false;
      state.isSuccessOpenPaymentUrl = false;
      state.openPaymentUrlError = error;
      console.error(error);
    },

    [OPEN_BAD_PAYMENT_URL_MODAL.INITIAL](state: IPaymentState) {
      state.isBadOpenPaymentUrlModal = true;
    },
    [OPEN_BAD_PAYMENT_URL_MODAL.SUCCEEDED](state: IPaymentState) {
      state.isBadOpenPaymentUrlModal = false;
    },

    [GO_RETRY_PAYMENT.INITIAL](state: IPaymentState) {
      state.isPaymentProcessing = true;
      state.retryCount++;
    },
    [GO_RETRY_PAYMENT.SUCCEEDED](state: IPaymentState) {
      state.isPaymentProcessing = false;
      state.retryCount = 0;
      state.paymentError = null;
      state.paymentUrl = null;
    },
    [GO_RETRY_PAYMENT.FAILED](state: IPaymentState, error: any) {
      state.isPaymentProcessing = false;
      state.paymentError = error;
      state.paymentUrl = null;
      console.error(error);
    },

    [SET_PAYMENT_STATUS_POLLING.INITIAL](state: IPaymentState) {
      state.isPaymentStatusPolling = true;
    },

    [SET_PAYMENT_STATUS_POLLING.SUCCEEDED](state: IPaymentState) {
      state.isPaymentStatusPolling = false;
    },
    [SET_PAYMENT_STATUS_POLLING.FAILED](state: IPaymentState, error: any) {
      state.isPaymentStatusPolling = false;
      console.error(error);
    },

    [SET_PAYMENT_STATUS.INITIAL](state: IPaymentState) {
      state.isPaymentStatusLoading = true;
      state.isPaymentStatusError = false;
      state.paymentStatus = "NEW";
      state.rawPaymentStatus = null;
    },
    [SET_PAYMENT_STATUS.SUCCEEDED](
      state: IPaymentState,
      paymentStatus: PaymentTransactionResource
    ) {
      state.isPaymentStatusLoading = false;
      state.paymentStatus = paymentStatus.status;
      state.rawPaymentStatus = paymentStatus;
    },
    [SET_PAYMENT_STATUS.FAILED](state: IPaymentState, error: any) {
      state.isPaymentStatusLoading = false;
      state.isPaymentStatusError = true;
      state.paymentStatus = "NEW";
      state.paymentStatusError = error;
    },

    [SET_PAYMENT_URL.INITIAL](state: IPaymentState) {
      state.isPaymentUrlLoading = true;
      state.isPaymentUrlError = false;
      state.paymentUrl = null;
    },
    [SET_PAYMENT_URL.SUCCEEDED](state: IPaymentState, paymentUrl: string) {
      state.isPaymentUrlLoading = false;
      state.paymentUrl = paymentUrl;
    },
    [SET_PAYMENT_URL.FAILED](state: IPaymentState, error: any) {
      state.isPaymentUrlLoading = false;
      state.isPaymentUrlError = true;
      state.paymentUrl = null;
      state.paymentUrlError = error;
    },
    // SET_PAYMENT_REQUIREMENTS
    [SET_PAYMENT_REQUIREMENTS.INITIAL](state: IPaymentState) {
      state.reservationRequirementsPending = true;
      state.reservationRequirementsError = null;
      state.reservationRequirements = null;
    },
    [SET_PAYMENT_REQUIREMENTS.SUCCEEDED](state: IPaymentState, reservationRequirements: ReservationRequirementsResource) {
      state.reservationRequirementsPending = false;
      state.reservationRequirements = reservationRequirements;
      state.reservationRequirementsError = null;
    },
    [SET_PAYMENT_REQUIREMENTS.FAILED](state: IPaymentState, error: any) {
      state.reservationRequirementsPending = false;
      state.reservationRequirementsError = error;
      state.reservationRequirements = null;
    },
    // SET_PAYMENT_REQUIREMENTS_BY_AMOUNT
    [SET_PAYMENT_REQUIREMENTS_BY_AMOUNT.INITIAL](state: IPaymentState) {
      state.reservationRequirementsPending = true;
      state.reservationRequirementsError = null;
      state.reservationRequirementsByAmount = null;
    },
    [SET_PAYMENT_REQUIREMENTS_BY_AMOUNT.SUCCEEDED](state: IPaymentState, reservationRequirements: ReservationRequirementsResource) {
      state.reservationRequirementsPending = false;
      state.reservationRequirementsByAmount = reservationRequirements;
      state.reservationRequirementsError = null;
    },
    [SET_PAYMENT_REQUIREMENTS_BY_AMOUNT.FAILED](state: IPaymentState, error: any) {
      state.reservationRequirementsPending = false;
      state.reservationRequirementsError = error;
      state.reservationRequirementsByAmount = null;
    },

    [SET_PAYMENT_EVALUATION.INITIAL](state: IPaymentState) {
      state.paymentEvaluationPending = true;
      state.paymentEvaluationError = null;
      state.paymentEvaluation = null;
    },
    [SET_PAYMENT_EVALUATION.SUCCEEDED](state: IPaymentState, paymentEvaluation: CcPaymentEvaluationResource) {
      state.paymentEvaluationPending = false;
      state.paymentEvaluation = paymentEvaluation;
      state.paymentEvaluationError = null;
    },
    [SET_PAYMENT_EVALUATION.FAILED](state: IPaymentState, error: any) {
      state.paymentEvaluationPending = false;
      state.paymentEvaluationError = error;
      state.paymentEvaluation = null;
    },
    [SET_PAYMENT_EVALUATION_BY_AMOUNT.INITIAL](state: IPaymentState) {
      state.paymentEvaluationPending = true;
      state.paymentEvaluationError = null;
      state.paymentEvaluationByAmount = null;
    },
    [SET_PAYMENT_EVALUATION_BY_AMOUNT.SUCCEEDED](state: IPaymentState, paymentEvaluation: CcPaymentEvaluationResource) {
      state.paymentEvaluationPending = false;
      state.paymentEvaluationByAmount = paymentEvaluation;
      state.paymentEvaluationError = null;
    },
    [SET_PAYMENT_EVALUATION_BY_AMOUNT.FAILED](state: IPaymentState, error: any) {
      state.paymentEvaluationPending = false;
      state.paymentEvaluationError = error;
      state.paymentEvaluationByAmount = null;
    },
    [SET_CURRENCY_RATES.INITIAL](state: IPaymentState) {
      state.exchangeRateResource = [];
    },
    [SET_CURRENCY_RATES.SUCCEEDED](state: IPaymentState, exchangeRateResource: ExchangeRateResource[]) {
      state.exchangeRateResource = exchangeRateResource;
    },
    [SET_CURRENCY_RATES.FAILED](state: IPaymentState, error: any) {
      console.error(error);
      state.exchangeRateResource = [];
    },
    [SET_VOUCHER.INITIAL](state: IPaymentState) {
      state.voucher = null;
    },
    [SET_VOUCHER.SUCCEEDED](state: IPaymentState, voucher: RootVoucherResource) {
      state.voucher = voucher;
    },
    [SET_VOUCHER.FAILED](state: IPaymentState, error: any) {
      console.error(error);
      state.voucher = null;
    },
    [SET_VOUCHER_PAYMENT.INITIAL](state: IPaymentState) {
      console.log("SET_VOUCHER_PAYMENT.INITIAL");
    },
    [SET_VOUCHER_PAYMENT.SUCCEEDED](state: IPaymentState, payload: any) {
      console.log("SET_VOUCHER_PAYMENT.SUCCEEDED", payload);
    },
    [SET_VOUCHER_PAYMENT.FAILED](state: IPaymentState, error: any) {
      console.error(error);
      console.log("SET_VOUCHER_PAYMENT.FAILED");
    },
    [CHANGE_VOUCHER_STATUS.INITIAL](state: IPaymentState) {
      console.log("CHANGE_VOUCHER_STATUS.INITIAL");
    },
    [CHANGE_VOUCHER_STATUS.SUCCEEDED](state: IPaymentState, payload: any) {
      console.log("CHANGE_VOUCHER_STATUS.SUCCEEDED", payload);
    },
    [CHANGE_VOUCHER_STATUS.FAILED](state: IPaymentState, error: any) {
      console.error(error);
      console.log("CHANGE_VOUCHER_STATUS.FAILED");
    }
  }
});
