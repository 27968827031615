
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TlIcon from "ui-modules/icons/icon";
import { isCancel } from "axios";
import { TranslateResult } from "vue-i18n";

@Component({
  methods: { isCancel },
  components: {
    TlIcon
  }
})
export default class PopupUniversal extends Vue {
  @Prop({ type: Boolean, default: true }) isCancelable?: boolean;
  @Prop({ type: Boolean, default: true }) isFooter?: boolean;
  @Prop(String)
    className!: string;

  @Prop({ required: true })
    title!: string | TranslateResult;

  defaultStyles: string[] = [
    "margin: auto;",
    "top: 50%;",
    "transform: translateY(-50%);"
  ];

  mounted() {
    this.$root.$el.classList.add("modal-open");
    this.$root.$el.append(this.$el);
  }

  beforeDestroy() {
    this.$root.$el.classList.remove("modal-open");

    if (this.$el.parentNode?.contains(this.$el)) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
